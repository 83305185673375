import { createAsyncThunk } from "@reduxjs/toolkit";

import {
    searchProfiles as searchProfilesApi,
    searchIdeas as searchIdeasApi,
} from "./service";

export const searchProfiles = createAsyncThunk("search/profiles", async (params = {}) => {
    const response = await searchProfilesApi(params);
    return response;
});

export const searchIdeas = createAsyncThunk("search/ideas", async (params = {}) => {
    const response = await searchIdeasApi(params);
    return response;
});