import axios from "axios";

import { interceptor } from "./interceptor";


const instance = axios.create({
  baseURL: 'http://64.225.50.44/api/v1',
  timeout: 60000,
  // withCredentials: true
});

//call axios interceptor
interceptor(instance);

export const addBasicAuth = (username, password) => {
  const token = `${username}:${password}`;
  const encodedToken = Buffer.from(token).toString('base64');
  localStorage.setItem('@token', encodedToken);
}

// axios.defaults.headers.common = { Authorization: `bearer ${token}` };
const request = async ({ method, url, data, headers, params }) => {
  let promise;
  if (method === 'get') {
    promise = instance.get(url, { headers, params });
  } else {
    promise = instance[method](url, data, { headers, params });
  }
  try {
    const response = await promise;
    return response.data;
  } catch (err) {
    let errData = err?.response?.data;
    let errMsg = 'Internal Server Error';
    for (let key in errData) {
      if (errData[key] && Array.isArray(errData[key]) && errData[key].length) {
        errMsg = errData[key][0];
      } else if (errData[key].length) {
        errMsg = errData[key];
      }
    }
    throw new Error(errMsg);
  }
};

export const get = (url, params) => request({ method: "get", url, params });
export const post = (url, data, params) => request({ method: "post", url, data, params });
export const put = (url, data, params) =>
  request({ method: "put", url, data, params });
export const patch = (url, data, params) =>
  request({ method: "patch", url, data, params });
export const del = (url, data) => request({ method: "delete", url, data });
