import { createSlice, isPending, isRejected } from "@reduxjs/toolkit";

import {
    getComments,
    addComment,
    clearComments,
    getIdeaDetails,
    toggleCommentsReply,
} from "./thunk";

const thunks = [getComments, addComment, clearComments, getIdeaDetails];

const initialState = {
    status: "idle",
    errorMessage: '',
    comments: [],
    data: null,
};

export const slice = createSlice({
    name: "ideaDetails",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(toggleCommentsReply.fulfilled, (state, action) => {
                state.comments = state.comments.map(item => ({ ...item, showReplies: action.meta.arg === item.id ? !item.showReplies : item.showReplies }));
            })
            .addCase(getIdeaDetails.fulfilled, (state, action) => {
                state.status = "idle";
                state.data = action?.payload;
            })
            .addCase(clearComments.fulfilled, (state, action) => {
                state.status = "idle";
                state.comments = [];
                state.data = null;
            })
            .addCase(addComment.fulfilled, (state, action) => {
                state.status = "idle";
            })
            .addCase(getComments.fulfilled, (state, action) => {
                state.status = "idle";
                const activeCommentIds = {};
                for (const comment of state.comments) {
                    if (comment.showReplies) {
                        activeCommentIds[comment.id] = true;
                    }
                }
                state.comments = action?.payload.map(item => ({ ...item, showReplies: activeCommentIds[item.id] ? true : false }));
            })
            .addMatcher(isPending(...thunks), (state) => {
                state.status = "loading";
            })
            .addMatcher(isRejected(...thunks), (state, action) => {
                state.status = "failed";
                state.errorMessage = action?.error?.message;
            });
    },
});

export const selectStatus = (state) => state.ideaDetails.status === "loading";
export const selectComments = (state) => state.ideaDetails.comments;
export const selectIdeaDetails = (state) => state.ideaDetails.data;


export default slice.reducer;
