import React from "react";
import * as moment from 'moment';

import AppImage from "../../../components/AppImage";
import { withRouter } from 'react-router-dom'


import './chatBubble.css';


const ChatBubble = ({ sentByMe, body, date, avatar, profileId, history }) => {

    if (!body) {
        return null;
    }

    return (
        <>
            {sentByMe ?
                <div className="chat-container my-message">
                    <div className="chat-time-container">
                        <p className="capitalize note fs-12 date no-margin">{moment(date).format('hh:mm a')}</p>
                    </div>
                    <div className="chat-bubble">
                        <p className="chat-message">{body}</p>
                    </div>
                </div> :
                <div className="chat-container">
                    <div>
                        <AppImage src={avatar} className="user-image pointer" onClick={()=> history.push(`/profile/${profileId}`)} />
                    </div>
                    <div className="chat-bubble">
                        <p className="chat-message">{body}</p>
                    </div>
                    <div className="chat-time-container">
                        <p className="capitalize note fs-12 date no-margin">{moment(date).format('hh:mm a')}</p>
                    </div>
                </div>
            }
        </>
    )
}

export default withRouter(ChatBubble);
