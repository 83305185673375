import React from "react";

import { Dropdown, Card, Button } from 'antd';


import settings from '../../../assets/icons/settings.png';
import logoutIcon from '../../../assets/icons/logout.png';
import user from '../../../assets/icons/user.png';
import pencil from '../../../assets/icons/pencil.png';
import { useDispatch } from "react-redux";
import { logout } from '../../auth/thunk';
import { withRouter } from 'react-router-dom';

import AppImage from "../../../components/AppImage";

import './navbarHeaderDropdown.css';


const NavbarHeaderDropdown = ({ children, history, setShowCreateNewIdeaModal }) => {
    const dispatch = useDispatch();

    const logoutUser = () => {
        dispatch(logout());
    }

    return (
        <Dropdown overlay={
            <>
                <Card style={{ width: 300 }} className="navbar-header-dropdown">
                    <div className="pointer">
                        <Button type="primary" className="capitalize menu-button fs-16" onClick={() => setShowCreateNewIdeaModal(true)}>
                            <AppImage src={pencil} className="icon" />create a new idea list
                        </Button>
                        <hr />

                    </div>
                    <div className="pointer">
                        <p className="menu-item capitalize fs-16" onClick={() => history.push('/my-profile')}>
                            <span>
                                <AppImage src={user} className="icon" />
                            </span>
                            My Profile
                        </p>
                        <hr />

                    </div>
                    <div className="pointer">
                        <p className="menu-item mt-10 capitalize fs-16" onClick={() => history.push('/my-profile/settings')}>
                            <span>
                                <AppImage src={settings} className="icon" />
                            </span>
                            Profile Settings
                        </p>
                        <hr />

                    </div>
                    <div onClick={logoutUser} className="pointer">
                        <p className="menu-item mt-10 mb-0 capitalize fs-16">
                            <span>
                                <AppImage src={logoutIcon} className="icon" />
                            </span>
                            Logout
                        </p>
                    </div>
                </Card>
            </>
        } placement="bottomRight" arrow
            trigger={['click']} >
            {children}
        </Dropdown >
    )
}

export default withRouter(NavbarHeaderDropdown);
