import React, { useEffect } from "react";

import {
    Card, Typography, Form,
    Input, Button,
    notification
} from 'antd';
import { Link, withRouter } from "react-router-dom";
import logo from '../../assets/images/nodePD_logo.svg';
import emailIcon from '../../assets/icons/emailIcon.png';
import passwordIcon from '../../assets/icons/passwordIcon.png';
import { useDispatch, useSelector } from "react-redux";
import { login } from './thunk';
import { selectToken } from './slice';

import AppImage from "../../components/AppImage";



import './login.css';

const { Title } = Typography;


const Login = ({ history }) => {
    const dispatch = useDispatch();
    const token = useSelector(selectToken);
    const [form] = Form.useForm();

    useEffect(async () => {
        if (token) {
            history.push('home')
        }
    }, [])

    const onFinish = async (values) => {
        console.log('Received values of form: ', values);
        try {
            const data = await dispatch(login(values));
            if (data?.error?.message) {
                notification.error({
                    message: 'Error',
                    description: data?.error?.message,
                });
            } else {
                notification.success({
                    message: 'Success',
                    description: 'Successfully Signed In',
                });
            }
        } catch (err) {
            notification.error({
                message: 'Error',
                description: 'Error while signing in.',
            });
        }
    };

    return (
        <>
            <div className="page-container login">
                <div className="center-container">
                    <div className="logo-container">
                        <AppImage src={logo} alt="10Ideas" />
                    </div>
                    <Card className="login-card">
                        <Title level={2} className="capitalize mb-10 title">Welcome back!</Title>
                        <p className="note fs-18 no-margin sub-title">Enter your credentials to access your account.</p>
                        <Form
                            form={form}
                            name="normal_login"
                            className="form login-form"
                            onFinish={onFinish}
                        >
                            <Form.Item
                                name="username"
                                rules={[{ required: true, message: 'Please input your email!' }]}
                            >
                                <Input prefix={<AppImage src={emailIcon} className="form-icon" />} placeholder="Enter your Email" />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[{ required: true, message: 'Please input your password!' }]}
                            >
                                <Input
                                    prefix={<AppImage src={passwordIcon} className="form-icon icon" />}
                                    type="password"
                                    placeholder="Password"
                                />
                            </Form.Item>

                            <Form.Item>
                                {/* <Link to="/home"> */}
                                <Button type="primary" htmlType="submit" className="form-button rounded fs-16" block>
                                    Sign in
                                </Button>
                                {/* </Link> */}
                            </Form.Item>

                            <Form.Item>
                                <p className="note fs-16 forgot-password-text">Forgot your password? <span className="primary">Reset Now</span></p>

                            </Form.Item>
                        </Form>
                    </Card>
                    <div className="other-page-container">
                        <p className="fs-20">Don't have an account?
                            <Link to="/signup">
                                <strong className="primary"> Create new account</strong>
                            </Link>
                        </p>
                    </div>
                </div>



            </div>
        </>

    )
}

export default withRouter(Login);
