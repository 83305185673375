import { createAsyncThunk } from "@reduxjs/toolkit";

import {
    getNotifications as getNotificationsApi,
    seenNotifications as seenNotificationsApi
} from "./service";

export const getNotifications = createAsyncThunk("notifications/get", async () => {
    const response = await getNotificationsApi();
    return response;
});


export const seenNotifications = createAsyncThunk("notifications/seen", async (id) => {
    const response = await seenNotificationsApi(id);
    return response;
});
