import { createSlice, isPending, isRejected } from "@reduxjs/toolkit";

import {
    getNotifications
} from "./thunk";

const thunks = [getNotifications];

const initialState = {
    status: "idle",
    errorMessage: '',
    data: [],
};

export const slice = createSlice({
    name: "notifications",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getNotifications.fulfilled, (state, action) => {
                state.status = "idle";
                state.data = action?.payload;
            })
            .addMatcher(isPending(...thunks), (state) => {
                state.status = "loading";
            })
            .addMatcher(isRejected(...thunks), (state, action) => {
                state.status = "failed";
                state.errorMessage = action?.error?.message;
            });
    },
});

export const selectStatus = (state) => state.leaderboard.status === "loading";
export const selectNotifications = (state) => state.notifications.data;


export default slice.reducer;
