import React, { useEffect, useState } from "react";

import { Dropdown, Card, Typography, Row, Col, Tooltip, Badge, Spin, Input } from 'antd';

import { useDispatch, useSelector } from "react-redux";
import { withRouter } from 'react-router-dom';
import { selectIdeas, selectProfiles } from './slice';
import { searchIdeas, searchProfiles } from './thunk';
import { SearchOutlined } from '@ant-design/icons';

import menuDots from '../../../assets/icons/menu-dots.png';

import * as moment from 'moment'
import AppImage from "../../../components/AppImage";

import './searchInput.css';

const { Title } = Typography;


const SearchInput = ({ children, history }) => {
    const dispatch = useDispatch();
    const [search, setSearch] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [showSearchDetails, setShowSearchDetails] = useState(false);
    const ideas = useSelector(selectIdeas);
    const profiles = useSelector(selectProfiles);


    const handleKeyPress = async (e) => {
        const key = e.keyCode || e.which;
        if (key === 13) {
            if (search?.length) {
                setLoading(true);
                setShowSearchDetails(true);
                await dispatch(searchIdeas({ search }));
                await dispatch(searchProfiles({ search }));
                setLoading(false);
            }
        }
    }

    const onChangeSearch = (e) => {
        if (showSearchDetails) {
            setShowSearchDetails(false);
        }
        setSearch(e.target.value)
    }

    const clearAll = () => {
        setShowSearchDetails(false);
        setSearch('');
    }


    return (
        <Dropdown overlay={
            <>
                {showSearchDetails &&
                    <Card style={{ width: 411 }} className="search-input">
                        <Row wrap={false} align="middle" className="search-input-title">
                            <Col flex="auto">
                                <p className="fs-20"><b>'{search}'</b> <span className="fs-16">Results</span></p>
                            </Col>
                            <Col flex="none" className="mb-5 pointer" onClick={clearAll}>
                                <span className="primary fs-14">Clear All</span>
                            </Col>
                        </Row>
                        <hr />
                        <div className="search-input-items-container">
                            {isLoading ?
                                <div className="text-center">
                                    <Spin />
                                </div>
                                :
                                <div className="search-item-container">
                                    {ideas?.slice(0, 3)?.map((idea, index) => <div key={index}>
                                        <p
                                            onClick={() => history.push(`/idea/${idea.id}`)}
                                            className="search-item pointer fs-16">
                                            <SearchOutlined className="search-icon" /> {idea.title}</p>
                                        <hr />
                                    </div>)}


                                    <Row className="user-search-container" gutter={[16, 16]}>
                                        {profiles.map((profile, index) =>
                                            <Col
                                                key={index}
                                                span={6}
                                                className="text-center pointer"
                                                onClick={() => history.push(`/profile/${profile.id}`)}>
                                                <AppImage className="user-image" src={profile.avatar} />
                                                <p className="no-margin">{profile.username}</p>
                                            </Col>)}


                                    </Row>
                                </div>
                            }
                        </div>

                    </Card>
                }
            </>
        } placement="bottomLeft" arrow
            trigger={['hover']}>
            <div className="search-container">
                <Input
                    disabled={isLoading}
                    value={search}
                    onChange={onChangeSearch}
                    onKeyPress={handleKeyPress}
                    className="search-input"
                    size="small"
                    placeholder="Search"
                    prefix={<SearchOutlined />} />
            </div>
        </Dropdown>
    )
}

export default withRouter(SearchInput);
