import React, { useEffect, useState } from "react";

import { Dropdown, Card, Typography, Row, Col, Tooltip, Badge, Spin } from 'antd';

import { useDispatch, useSelector } from "react-redux";
import { withRouter } from 'react-router-dom';
import { selectNotifications } from './slice';
import { getNotifications, seenNotifications } from './thunk';

import menuDots from '../../../assets/icons/menu-dots.png';

import * as moment from 'moment'
import AppImage from "../../../components/AppImage";

import './notificationDropdown.css';

const { Title } = Typography;


const NotificationDropdown = ({ children, history }) => {
    const dispatch = useDispatch();
    const notifications = useSelector(selectNotifications);
    const [isLoading, setLoading] = useState(false);


    useEffect(() => {
        dispatch(getNotifications());
    }, [])

    // const notifications = [
    //     { image: notification1, createdAt: '2 hours ago', title: <span><strong>Natalia Zee</strong> followed you.</span> },
    //     { image: notification2, createdAt: '2 hours ago', title: <span><strong>Dave Parker</strong> commented on your idea <strong>FYP Concepts</strong></span> },
    //     { image: notification3, createdAt: '2 hours ago', title: <span><strong>Dave Parker</strong> commented on your idea <strong>FYP Concepts</strong></span> },
    //     { image: notification1, createdAt: '2 hours ago', title: <span><strong>Natalia Zee</strong> followed you.</span> },
    // ]

    const redirectToIdea = (ideaId) => {
        history.push(`/idea/${ideaId}`)
    }

    const redirectToProfile = (profileId) => {
        history.push(`/profile/${profileId}`)
    }

    const getTitle = (data) => {
        if (data.notification_type === 1) {
            return <span><strong className="pointer" onClick={() => redirectToProfile(data.from_user)}>{data.from_user_name}</strong> like your post <strong className="pointer" onClick={() => redirectToIdea(data.post)}>{data.post_title}</strong></span>
        } else if (data.notification_type === 2) {
            return <span><strong className="pointer" onClick={() => redirectToProfile(data.from_user)}>{data.from_user_name}</strong> comment your post <strong className="pointer" onClick={() => redirectToIdea(data.post)}>{data.post_title}</strong></span>
        } else if (data.notification_type === 3) {
            return <span><strong className="pointer" onClick={() => redirectToProfile(data.from_user)}>{data.from_user_name}</strong> followed you</span>
        }
        return null;
    }

    const seenNotification = async (data) => {
        setLoading(true);
        await dispatch(seenNotifications(data.id));
        await dispatch(getNotifications());
        setLoading(false);
    }

    return (
        <Dropdown overlay={
            <>
                <Card style={{ width: 296 }} className="notification-dropdown">
                    <Row wrap={false} align="middle">
                        <Col flex="auto">
                            <Title level={4} className="capitalize">Notification</Title>
                        </Col>
                        {/* <Col flex="none" className="mb-5">
                            <span className="primary fs-14">Clear All</span>
                        </Col> */}
                    </Row>
                    <hr />
                    <div className="notification-dropdown-items-container">
                        {isLoading ?
                            <div className="text-center">
                                <Spin />
                            </div>
                            :
                            notifications.map((item, index) => <>
                                {/* <Tooltip title="Mark as read" placement="left"> */}

                                <Row wrap={false} align="middle" className="mt-20 mb-20" >
                                    <Col flex="none">
                                        <AppImage src={item.icon} className='user-image' />
                                    </Col>
                                    <Col flex="auto" style={{ paddingLeft: 20 }}>
                                        <p className="fs-12 no-margin">{moment(item.date).fromNow()}</p>
                                        <p className="fs-16 no-margin">{getTitle(item)}</p>

                                    </Col>
                                    <Col flex="auto">
                                        {/* <AppImage src={menuDots} className="menu-icon pointer" onClick={() => seenNotification(item)} /> */}
                                        <Dropdown overlay={
                                            <>
                                                <Card style={{ width: 202 }} className="profile-dropdown">
                                                    <div className="pointer" onClick={() => seenNotification(item)}>
                                                        <p className="menu-item capitalize fs-16 mb-0">
                                                            Mark as read
                                                        </p>
                                                    </div>
                                                </Card>
                                            </>
                                        } placement="bottomRight" arrow
                                            trigger={['hover']} >
                                            <AppImage src={menuDots} className="menu-icon pointer ml-5" />
                                        </Dropdown>
                                    </Col>
                                </Row>
                                {/* </Tooltip> */}
                                {notifications.length !== (index + 1) && <hr />}
                            </>)}
                    </div>

                </Card>
            </>
        } placement="bottomRight" arrow
            trigger={['click']}>
            <Badge count={notifications.length}>
                {children}
            </Badge>
        </Dropdown>
    )
}

export default withRouter(NotificationDropdown);
