import { createSlice, isPending, isRejected } from "@reduxjs/toolkit";

import {
    getLeaderboardIdeas,
    getUserLeaderboard
} from "./thunk";

const thunks = [getLeaderboardIdeas, getUserLeaderboard];

const initialState = {
    status: "idle",
    errorMessage: '',
    ideas: [],
};

export const slice = createSlice({
    name: "leaderboard",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getLeaderboardIdeas.fulfilled, (state, action) => {
                state.status = "idle";
                state.ideas = action?.payload;
            })
            .addCase(getUserLeaderboard.fulfilled, (state, action) => {
                state.status = "idle";
                state.ideas = action?.payload;
            })
            .addMatcher(isPending(...thunks), (state) => {
                state.status = "loading";
            })
            .addMatcher(isRejected(...thunks), (state, action) => {
                state.status = "failed";
                state.errorMessage = action?.error?.message;
            });
    },
});

export const selectStatus = (state) => state.leaderboard.status === "loading";
export const selectLeaderboardIdeas = (state) => state.leaderboard.ideas;


export default slice.reducer;
