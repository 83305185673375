import { post, get, put, del, patch } from '../../../utils/httpService';

const SERVICE_URLS = {
  getIdeas: () => `/posts/`,
  postIdeas: () => `/posts/`,
  likeIdea: (id) => `/posts/details/${id}/like-unlike/`,
  deleteIdea: (id) => `/posts/details/${id}/`,
  updateIdea: (id) => `/posts/details/${id}/`,
};

export const getIdeas = () => get(SERVICE_URLS.getIdeas());
export const postIdeas = (data) => post(SERVICE_URLS.postIdeas(), data);
export const likeIdea = (id) => post(SERVICE_URLS.likeIdea(id), {});
export const deleteIdea = (id) => del(SERVICE_URLS.deleteIdea(id), {});
export const updateIdea = (id, data) => patch(SERVICE_URLS.updateIdea(id), data);