import { createAsyncThunk } from "@reduxjs/toolkit";

import {
    getIdeas as getIdeasApi,
    postIdeas as postIdeasApi,
    likeIdea as likeIdeaApi,
    deleteIdea as deleteIdeaApi,
    updateIdea as updateIdeaApi,
} from "./service";

export const getIdeas = createAsyncThunk("home/posts", async (data) => {
    const response = await getIdeasApi(data);
    return response;
});

export const likeIdea = createAsyncThunk("home/posts/like", async (id) => {
    const response = await likeIdeaApi(id);
    return response;
});

export const postIdeas = createAsyncThunk("home/posts/create", async (data) => {
    const response = await postIdeasApi(data);
    return response;
});

export const deleteIdea = createAsyncThunk("home/posts/delete", async (id) => {
    const response = await deleteIdeaApi(id);
    return response;
});

export const updateIdea = createAsyncThunk("home/posts/update", async ({ id, data }) => {
    const response = await updateIdeaApi(id, data);
    return response;
});

