import { post, get, put, del } from '../../../utils/httpService';

const SERVICE_URLS = {
  getUsers: () => `/direct/users/`,
  getInbox: () => `/direct/inbox/`,
  startConversation: (username) => `/direct/new-conversation/${username}/`,
  sendMessage: () => `/direct/send-message/`,
};

export const getUsers = () => get(SERVICE_URLS.getUsers());
export const getInbox = () => get(SERVICE_URLS.getInbox());
export const startConversation = (username) => post(SERVICE_URLS.startConversation(username));
export const sendMessage = (data) => post(SERVICE_URLS.sendMessage(), data);