import React from "react";

import userDefaultImage from '../assets/images/user-default.svg';




const AppImage = ({ src, ...props }) => {
    let imageSrc = src?.indexOf('/media/') === 0 ? `http://64.225.50.44${src}` : src;
    if (!imageSrc) {
        imageSrc = userDefaultImage;
    }


    return <img src={imageSrc} {...props} />
}

export default AppImage;