import React from 'react';

import { Redirect } from 'react-router-dom';
import Login from '../views/auth/login';
import Signup from '../views/auth/signup';
import Home from '../views/main/home/home';
import Profile from '../views/main/profile/profile';
import IdeaDetails from '../views/main/ideaDetails/ideaDetails';
import ProfileSettings from '../views/main/profileSettings/profileSettings';
import Messages from '../views/main/messages/messages';


let routes = [
    {
        path: '/',
        exact: true,
        isChild: false,
        private: false,
        component: () => <Redirect to="login" />

    },
    {
        path: '/signup',
        exact: true,
        isChild: false,
        private: false,
        component: (props) => <Signup {...props} />

    },
    {
        path: '/login',
        exact: true,
        isChild: false,
        private: false,
        component: (props) => <Login {...props} />

    },
    {
        path: '/home',
        exact: true,
        isChild: true,
        private: true,
        component: (props) => <Home {...props} />
    },
    {
        path: '/profile/:id',
        exact: true,
        isChild: true,
        private: true,
        component: (props) => <Profile {...props} />
    },
    {
        path: '/my-profile',
        exact: true,
        isChild: true,
        private: true,
        component: (props) => <Profile {...props} myProfile={true} />
    },
    {
        path: '/my-profile/settings',
        exact: true,
        isChild: true,
        private: true,
        component: (props) => <ProfileSettings {...props} />
    },
    {
        path: '/idea/:id',
        exact: true,
        isChild: true,
        private: true,
        component: (props) => <IdeaDetails {...props} />
    },
    {
        path: '/messages',
        exact: true,
        isChild: true,
        private: true,
        component: (props) => <Messages {...props} />
    }
];

export default routes;
