import React, { useEffect, useState } from "react";

import { Row, Col, Card, Typography, Form, Input, Button, Upload, notification } from 'antd';
import { Link } from 'react-router-dom';


import settings from '../../../assets/icons/settings.png';
import password from '../../../assets/icons/password.png';
import passwordInactive from '../../../assets/icons/password-inactive.png';
import userDefault from '../../../assets/images/user-default.svg';
import uploadIcon from '../../../assets/icons/upload.png';
import bell from '../../../assets/icons/bell.png';
import bellInactive from '../../../assets/icons/bell-inactive.png';
import logout from '../../../assets/icons/logout.png';

import { useSelector, useDispatch } from "react-redux";
import { selectMyProfile, selectStatus } from '../profile/slice';
import { getMyProfile, updateMyProfile } from '../profile/thunk';

import AppImage from "../../../components/AppImage";

import './profileSettings.css';


const { Title } = Typography;

const ProfileSettings = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const myProfileData = useSelector(selectMyProfile);
    const isLoading = useSelector(selectStatus);
    const [fileList, setFileList] = useState([]);

    useEffect(() => {
        if (!myProfileData) {
            dispatch(getMyProfile());
        }
    }, [])

    useEffect(() => {
        if (myProfileData) {
            form.setFieldsValue({
                name: `${myProfileData?.first_name} ${myProfileData?.last_name}`.trim(),
                email: myProfileData?.email,
                bio: myProfileData?.bio,
            });
        }
    }, [myProfileData])

    const menuItems = [
        { title: 'profile settings', icon: settings, isActive: true, inActiveIcon: passwordInactive },
        // { title: 'security & login', icon: password, isActive: false, inActiveIcon: passwordInactive },
        // { title: 'notification settings', icon: bell, isActive: false, inActiveIcon: bellInactive },
        { title: 'logout', icon: logout, isActive: false, danger: true, inActiveIcon: logout },
    ];
    const pClassNames = ["menu-item capitalize mb-25 mt-25 fs-18"];
    const getMenuItemClassNames = (item) => {
        const classNames = [...pClassNames];
        if (item.danger) {
            classNames.push('danger');
        } else if (item.isActive) {
            classNames.push('primary');
        }
        return classNames.join(' ');
    }

    const beforeUpload = (file) => {
        setFileList([file]);
        return false;
    }

    const onFinish = async (values) => {
        const names = values?.name?.trim().split(' ');
        const data = {
            first_name: names?.length ? names[0] : '',
            last_name: names?.length > 1 ? names.slice(1).join(' ') : '',
            bio: values.bio,
            email: values.email,
        };
        const formData = new FormData();
        for (const key in data) {
            formData.set(key, data[key]);
        }
        if (values.avatar?.file) {
            formData.set('avatar', values.avatar?.file);
        }
        try {
            await dispatch(updateMyProfile(formData));
            setFileList([]);
            notification.success({
                message: 'Success',
                description: 'Profile updated successfully.',
            });
        } catch (err) {
            notification.error({
                message: 'Error',
                description: 'Error while updating profile.',
            });
        }

    };

    return (
        <>
            <div className="page-container home-page profile-settings">
                {myProfileData &&
                    <Row wrap={false} align="middle">
                        <Col flex="none" style={{
                            width: 293,
                        }}>
                            <div className="left-container fixed">
                                <Card style={{
                                    width: 293,
                                }}>
                                    <Title level={4} className="capitalize">Settings</Title>
                                    <hr />
                                    {menuItems.map((item, index) => <div key={index} >
                                        <p className={getMenuItemClassNames(item)}>
                                            <span>
                                                {item.isActive ? <AppImage src={item.icon} className="icon" /> :
                                                    <AppImage src={item.inActiveIcon} className="icon" />
                                                }
                                            </span>
                                            {item.title}
                                        </p>
                                        {menuItems.length !== (index + 1) && <hr />}

                                    </div>)}

                                </Card>
                            </div>
                        </Col>
                        <Col flex="auto" className="form-card">
                            <div className="center-container">
                                <Card>
                                    <Title level={4} className="capitalize fs-18 mb-25">general profile settings</Title>
                                    <hr />
                                    <Form
                                        layout="vertical"
                                        form={form}
                                        className="profile-settings-form"
                                        onFinish={onFinish}
                                    >
                                        <Form.Item name="avatar" className="text-center">
                                            <Upload
                                                name="avatar"
                                                // listType="picture-card"
                                                className="avatar-uploader"
                                                showUploadList={true}
                                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                beforeUpload={beforeUpload}
                                                multiple={false}
                                                fileList={fileList}
                                            >
                                                <AppImage src={myProfileData?.avatar || userDefault} className="avatar" />
                                                <AppImage src={uploadIcon} className='upload-icon' />
                                            </Upload>
                                        </Form.Item>
                                        <Form.Item label="Name" name="name">
                                            <Input placeholder="Enter name" className="profile-input" />
                                        </Form.Item>
                                        <Form.Item label="Email Address" name="email">
                                            <Input placeholder="Enter email address" className="profile-input" />
                                        </Form.Item>
                                        <Form.Item label="Contact Number" name="contact">
                                            <Input placeholder="Enter contact number" className="profile-input" />
                                        </Form.Item>
                                        <Form.Item label="Bio Details" name="bio">
                                            <Input placeholder="Enter Bio Details" className="profile-input" />
                                        </Form.Item>
                                        <Form.Item className="action-container text-right">
                                            <Button type="secondary" htmlType="button">Cancel</Button>
                                            <Button type="primary" htmlType="submit" loading={isLoading} >Update</Button>
                                        </Form.Item>
                                    </Form>
                                </Card>

                            </div>
                        </Col>
                    </Row>
                }
            </div>
        </>

    )
}

export default ProfileSettings;
