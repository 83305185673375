import { createAsyncThunk } from "@reduxjs/toolkit";

import {
    getUsers as getUsersApi,
    getInbox as getInboxApi,
    startConversation as startConversationApi,
    sendMessage as sendMessageApi,
} from "./service";

export const getUsers = createAsyncThunk("messages/users", async (data) => {
    const response = await getUsersApi(data);
    return response;
});

export const getInbox = createAsyncThunk("messages/inbox", async (data) => {
    const response = await getInboxApi(data);
    return response;
});

export const startConversation = createAsyncThunk("messages/start-conversation", async (username) => {
    const response = await startConversationApi(username);
    return response;
});

export const sendMessage = createAsyncThunk("messages/send-message", async (data) => {
    const response = await sendMessageApi(data);
    return response;
});