import React from "react";

import { Row, Col, Card, Typography, Button } from 'antd';
import { withRouter } from 'react-router-dom';


import user1 from '../../../assets/images/user1.png';
import user2 from '../../../assets/images/user2.png';
import user3 from '../../../assets/images/user3.png';
import user4 from '../../../assets/images/user4.png';
import user5 from '../../../assets/images/user5.png';
import user6 from '../../../assets/images/user6.png';
import user7 from '../../../assets/images/user7.png';
import user8 from '../../../assets/images/user8.png';

import AppImage from "../../../components/AppImage";

import './friendList.css';


const { Title } = Typography;

const FriendList = ({ friends, title, history }) => {
    return (
        <>
            <Card style={{ marginTop: 20 }} className="friends-container">
                <Title level={3} className="capitalize">{title}</Title>
                <hr className="mt-20" />
                <Row gutter={[8, 24]} style={{ marginTop: 30 }}>
                    {friends?.map((item, index) =>
                        <Col key={index} xs={12} sm={12} md={12} lg={12} xl={8} xxl={8} >
                            <Row wrap={false} align="middle">
                                <Col flex="none">
                                    <AppImage
                                        alt="user"
                                        src={item.avatar}
                                        className="friend-image"
                                    />
                                </Col>
                                <Col flex="auto" className="friend-name">
                                    <Title 
                                        level={5} 
                                        className="capitalize no-margin pointer"
                                        onClick={()=> history.push(`/profile/${item.profile_id}`)}
                                        >{item.username}</Title>
                                </Col>
                            </Row>
                        </Col>)}

                </Row>
            </Card>
        </>

    )
}

export default withRouter(FriendList);
