import { post, get, put, del, addBasicAuth } from '../../utils/httpService';

const SERVICE_URLS = {
  login: () => `/accounts/login/`,
  register: () => `/accounts/register/`,
};

export const login = (data) => {
  addBasicAuth(data.username, data.password);
  return post(SERVICE_URLS.login(), data)
};
export const register = (data) => post(SERVICE_URLS.register(), data);
