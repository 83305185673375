import { createSlice, isPending, isRejected } from "@reduxjs/toolkit";

import {
    getProfile,
    getMyProfile,
    updateMyProfile,
    getMyIdeas
} from "./thunk";

const thunks = [getProfile, getMyProfile, updateMyProfile, getMyIdeas];

const initialState = {
    status: "idle",
    errorMessage: '',
    data: null,
    myProfile: null,
    myIdeas: [],
};

export const slice = createSlice({
    name: "profile",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getMyIdeas.fulfilled, (state, action) => {
                state.status = "idle";
                state.myIdeas = action?.payload;
            })
            .addCase(updateMyProfile.fulfilled, (state, action) => {
                state.status = "idle";
                state.myProfile = action?.payload;
            })
            .addCase(getProfile.pending, (state, action) => {
                state.data = null;
            })
            .addCase(getProfile.fulfilled, (state, action) => {
                state.status = "idle";
                state.data = action?.payload;
            })
            .addCase(getMyProfile.pending, (state, action) => {
                state.myProfile = null;
            })
            .addCase(getMyProfile.fulfilled, (state, action) => {
                state.status = "idle";
                state.myProfile = action?.payload;
            })
            .addMatcher(isPending(...thunks), (state) => {
                state.status = "loading";
            })
            .addMatcher(isRejected(...thunks), (state, action) => {
                state.status = "failed";
                state.errorMessage = action?.error?.message;
            });
    },
});

export const selectStatus = (state) => state.profile.status === "loading";
export const selectProfileData = (state) => state.profile.data;
export const selectMyProfile = (state) => state.profile.myProfile;
export const selectMyIdeas = (state) => state.profile.myIdeas;


export default slice.reducer;
