import React, { useState } from "react";

import { Layout, Menu, Row, Col, Button, Input, Badge } from 'antd';
import { withRouter } from 'react-router-dom';
import { SearchOutlined, MenuOutlined, MailFilled } from '@ant-design/icons';
import logo from '../../assets/images/nodePD_logo.svg';
import user1 from '../../assets/images/user1.png';
import notification from '../../assets/icons/notification.png';
import stats from '../../assets/icons/stats.png';
import pencil from '../../assets/icons/pencil.png';

import { useDispatch, useSelector } from "react-redux";
import { selectMyProfile } from './profile/slice';

import CreateNewIdea from './CreateNewIdea/createNewIdea';
import ProfileDropdown from "./profileDropdown/profileDropdown";
import NotificationDropdown from "./notificationDropdown/notificationDropdown";
import NavbarHeaderDropdown from './navbarHeaderDropdown/navbarHeaderDropdown';

import SearchInput from './searchInput/searchInput';

import AppImage from "../../components/AppImage";

import './main.css';

const { Header, Content } = Layout;


const Main = ({ children, history }) => {
    const dispatch = useDispatch();
    const myProfile = useSelector(selectMyProfile);
    const [showCreateNewIdeaModal, setShowCreateNewIdeaModal] = useState(false);

    const goToHomePage = () => {
        history.push('/home');
    }

    const goToMessagesPage = () => {
        history.push('/messages');
    }

    return (
        <>
            <Layout className="page-container main-layout scroll-auto">


                <Header className="header" style={{}}>
                    <Row>
                        <Col xl={2} lg={4} md={4} sm={4} xs={4} >
                            <AppImage src={logo} alt="10Ideas" width={90} height={'auto'} className="pointer" onClick={goToHomePage} />

                        </Col>
                        <Col xl={6} lg={0} md={0} xs={0} sm={0}>
                            {/* <div className="search-container">
                                <Input className="search-input" size="small" placeholder="Search" prefix={<SearchOutlined />} />
                            </div> */}
                            <SearchInput />
                        </Col>
                        <Col xl={6} lg={6} md={0} xs={0} sm={0}>
                            <Menu mode="horizontal" defaultSelectedKeys={['1']} className="fs-16" style={{
                                justifyContent: 'flex-end'
                            }}>
                                <Menu.Item key="1" onClick={goToHomePage}>Home</Menu.Item>
                            </Menu>
                        </Col>
                        <Col xl={10} lg={14} md={0} xs={0} sm={0}>
                            <div style={{ display: 'flex', height: '100%' }}>
                                <div className="new-idea-btn">
                                    <Button type="primary" className="capitalize menu-button fs-16" onClick={() => setShowCreateNewIdeaModal(true)}>
                                        <AppImage src={pencil} className="icon" />create a new idea list
                                    </Button>
                                </div>
                                <div className="profile-container">
                                    <div className="icon-container">
                                        <Badge
                                        //  count={9}
                                         
                                         >
                                            <Button type="default" shape="circle" className="btn pointer icon" icon={<MailFilled />} onClick={goToMessagesPage} />
                                        </Badge>
                                        {/* <AppImage src={stats} className="icon pointer" onClick={goToMessagesPage} /> */}
                                        <NotificationDropdown>
                                            <AppImage src={notification} className="icon pointer" />
                                        </NotificationDropdown>
                                        <ProfileDropdown>
                                            {myProfile?.avatar ?
                                                <AppImage
                                                    alt="user"
                                                    className="icon pointer user-image"
                                                    src={myProfile?.avatar}
                                                />
                                                :
                                                <></>
                                            }
                                        </ProfileDropdown>

                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xl={0} lg={0} md={20} xs={20} sm={20}>
                            <div className="text-right">
                                <NavbarHeaderDropdown setShowCreateNewIdeaModal={setShowCreateNewIdeaModal}>
                                    <MenuOutlined />
                                </NavbarHeaderDropdown>
                            </div>
                        </Col>
                    </Row>
                </Header>
                <Content className="main-content">
                    {children}
                    <CreateNewIdea visible={showCreateNewIdeaModal} onCancel={() => setShowCreateNewIdeaModal(false)} />
                </Content>
            </Layout>
        </>

    )
}

export default withRouter(Main);
