import { createAsyncThunk } from "@reduxjs/toolkit";

import {
    getComments as getCommentsApi,
    addComment as addCommentApi,
    getIdeaDetails as getIdeaDetailsApi,
} from "./service";

export const clearComments = createAsyncThunk("posts/details/comments/clear", async () => {
    return null;
});

export const getComments = createAsyncThunk("posts/details/comments", async (id) => {
    const response = await getCommentsApi(id);
    return response;
});

export const addComment = createAsyncThunk("posts/details/comments/add", async (data) => {
    const response = await addCommentApi(data);
    return response;
});

export const getIdeaDetails = createAsyncThunk("posts/details", async (id) => {
    const response = await getIdeaDetailsApi(id);
    return response;
});

export const toggleCommentsReply = createAsyncThunk("posts/details/comments/reply/toggle", async (id) => {
    console.log("Show replies : ", id);
    return true;
});

