import React, { useEffect, useState } from "react";

import { Row, Col, Card, Typography, Select, Spin } from 'antd';

import { useDispatch, useSelector } from "react-redux";
import { selectLeaderboardIdeas } from './slice';
import { getLeaderboardIdeas, getUserLeaderboard } from './thunk';
import { withRouter } from 'react-router-dom';

import AppImage from '../../../components/AppImage';

import './leaderBoard.css';

const { Title } = Typography;
const { Option } = Select;


const LeaderBoard = ({ history }) => {

    const dispatch = useDispatch();
    const leaderboardIdeas = useSelector(selectLeaderboardIdeas);
    const [leaderboardType, setLeaderboardType] = useState('post');
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        getLeaderboardData(leaderboardType);
    }, [])

    const getLeaderboardData = async (query) => {
        setLoading(true);
        if (query === 'post') {
            await dispatch(getLeaderboardIdeas());
        } else {
            const params = {};
            params[query] = true;
            await dispatch(getUserLeaderboard(params));
        }
        setLoading(false);

    }

    const handleDashboardTypeChange = (value) => {
        setLeaderboardType(value);
        getLeaderboardData(value);
    }

    return (
        <>
            <Card style={{ width: 293, height: '74vh' }} className="leaderboard-card">
                <div className="leaderboard-header-container">
                    <Row>
                        <Col flex="none">
                            <Title level={4} className="capitalize">Leaderboard</Title>
                        </Col>
                        <Col flex="auto">
                            <Select style={{ width: 120, marginLeft: 10 }} defaultValue={leaderboardType} onChange={handleDashboardTypeChange}>
                                <Option value="post">Post</Option>
                                <Option value="likes">Likes</Option>
                                <Option value="comments">Comments</Option>
                                <Option value="forks">Forks</Option>
                                <Option value="win_challenge">Win Challenge</Option>
                            </Select>
                        </Col>
                    </Row>
                    <hr />
                </div>
                {isLoading ?
                    <div className="spin-container leaderboard-list-container">
                        <Spin />
                    </div> :
                    <div className="leaderboard-list-container">
                        {leaderboardIdeas.map((item, index) => <div key={index}>
                            <Row style={{ marginBottom: 15 }}>
                                {leaderboardType !== 'post' && <Col flex="none">
                                    <AppImage src={item.avatar} alt='user-image' className="user-image" />
                                </Col>
                                }
                                <Col flex="auto" className={leaderboardType === 'post' ? "title-container" : 'user-name-container'}>
                                    {leaderboardType === 'post' ? <>
                                        <p className="capitalize primary no-margin sm-font pointer" onClick={() => history.push(`/profile/${item.author_id}`)}>{item.author}</p>
                                        <p className="capitalize no-margin fs-16 pointer" onClick={() => history.push(`/idea/${item.id}`)}>{item.title}</p>
                                    </> :
                                        <>
                                            <p className="capitalize primary no-margin fs-16 pointer" onClick={() => history.push(`/profile/${item.id}`)}>{item.username}</p>
                                        </>
                                    }
                                </Col>
                                <Col flex="none" style={{
                                    justifyContent: 'center',
                                    display: 'flex',
                                    alignContent: 'flex-end',
                                    alignItems: 'flex-end',
                                    marginBottom: 2
                                }}>
                                    {leaderboardType === 'post' &&
                                        <p className="no-margin note sm-font text-right">{item.likes_count} Likes</p>
                                    }
                                    {leaderboardType === 'likes' &&
                                        <p className="no-margin note sm-font text-right">{item.likes_received} Likes</p>
                                    }
                                    {leaderboardType === 'comments' &&
                                        <p className="no-margin note sm-font text-right">{item.comment_received} Comments</p>
                                    }
                                    {leaderboardType === 'forks' &&
                                        <p className="no-margin note sm-font text-right">{item.fork_received} Forks</p>
                                    }
                                    {leaderboardType === 'win_challenge' &&
                                        <p className="no-margin note sm-font text-right">{item.win_challenges} Win Challenges</p>
                                    }
                                </Col>
                            </Row>
                            {leaderboardIdeas.length !== (index + 1) && <hr />}

                        </div>)}
                    </div>
                }


            </Card>
        </>

    )
}

export default withRouter(LeaderBoard);
