import React from "react";

import {
    Card, Typography, Form,
    Input, Button,
    notification,
} from 'antd';
import { Link, withRouter } from "react-router-dom";
import logo from '../../assets/images/nodePD_logo.svg';
import emailIcon from '../../assets/icons/emailIcon.png';
import passwordIcon from '../../assets/icons/passwordIcon.png';
import user from '../../assets/icons/user.png';
import { useDispatch } from "react-redux";
import { register } from './thunk';

import AppImage from '../../components/AppImage';


import './login.css';

const { Title } = Typography;


const Signup = ({
    history
}) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const onFinish = async () => {
        const values = form.getFieldsValue();
        console.log('Received values of form: ', values);
        try {
            const data = await dispatch(register(values));
            if (data?.error?.message) {
                notification.error({
                    message: 'Error',
                    description: data?.error?.message,
                });
            } else {
                notification.success({
                    message: 'Success',
                    description: 'New Account Created Successfully',
                });
                history.push('/login');
            }

        } catch (err) {
            notification.error({
                message: 'Error',
                description: 'Error while signup.',
            });
            console.log(err);
        }
    };

    return (
        <>
            <div className="page-container login sign-up">
                <div className="center-container">
                    <div className="logo-container">
                        <AppImage src={logo} alt="10Ideas" />
                    </div>
                    <Card className="login-card sign-up-card">
                        <Title level={2} className="capitalize title mb-10">Create New Account</Title>
                        <p className="note fs-18 no-margin sub-title">Enter your credentials to create new account.</p>
                        <Form
                            form={form}
                            className="form login-form"
                        // onFinish={onFinish}
                        >
                            <Form.Item
                                name="email"
                                rules={[{ required: true, message: 'Please input your email!' }, { type: 'email', message:'Please enter valid email' }]}
                            >
                                <Input prefix={<AppImage src={emailIcon} className="form-icon" />} placeholder="Enter you Email" />
                            </Form.Item>
                            <Form.Item
                                name="username"
                                rules={[{ required: true, message: 'Please input your username!' }]}
                            >
                                <Input
                                    prefix={<AppImage src={user} className="form-icon" />}
                                    type="text"
                                    placeholder="Username"

                                />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[{ required: true, message: 'Please input your password!' }]}
                                hasFeedback
                            >
                                <Input
                                    prefix={<AppImage src={passwordIcon} className="form-icon" />}
                                    type="password"
                                    placeholder="Password"

                                />
                            </Form.Item>

                            <Form.Item
                                name="confirmPassword"
                                dependencies={['password']}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please confirm your password!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                        },
                                    }),
                                ]}
                            >
                                <Input
                                    prefix={<AppImage src={passwordIcon} className="form-icon" />}
                                    type="password"
                                    placeholder="Confirm Password"
                                />
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" htmlType="button" className="form-button rounded fs-16" block onClick={onFinish}>
                                    Create now
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                    <div className="other-page-container">
                        <p className="fs-20">Already have an account?
                            <Link to="/login">
                                <strong className="primary"> Login now</strong>
                            </Link>
                        </p>
                    </div>
                </div>



            </div>
        </>

    )
}

export default withRouter(Signup);
