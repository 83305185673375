import { post, get, put, del } from '../../../utils/httpService';

const SERVICE_URLS = {
  getProfile: (id) => `/profiles/profiles/${id}/`,
  getMyProfile: () => `/profiles/details/`,
  updateMyProfile: () => `/profiles/details/`,
  followProfile: (id) => `/profiles/profiles/${id}/follow/`,
  unfollowProfile: (id) => `/profiles/profiles/${id}/unfollow/`,
  getMyIdeas: () => `/posts/details/`,
};

export const getProfile = (id, params) => get(SERVICE_URLS.getProfile(id), params);
export const getMyProfile = (params) => get(SERVICE_URLS.getMyProfile(), params);
export const updateMyProfile = (data) => put(SERVICE_URLS.updateMyProfile(), data);
export const followProfile = (id) => get(SERVICE_URLS.followProfile(id));
export const unfollowProfile = (id) => get(SERVICE_URLS.unfollowProfile(id));
export const getMyIdeas = (params) => get(SERVICE_URLS.getMyIdeas(), params);