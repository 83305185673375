import React, { useState, useEffect } from "react";

import { Row, Col, Card, Typography, Button, Skeleton, Spin, Tabs, } from 'antd';
import { Link, withRouter } from 'react-router-dom';



import IdeaCard from '../home/ideaCard';
import LeaderBoard from "../leaderBoard/leaderBoard";
import FriendList from "../friendList/friendList";
import user1 from '../../../assets/images/user1.png';

import { useDispatch, useSelector } from "react-redux";
import { selectProfileData, selectMyProfile, selectMyIdeas } from './slice';
import { getProfile, getMyProfile, followProfile, unfollowProfile, getMyIdeas } from './thunk';
import { selectUser } from '../../auth/slice';

import AppImage from "../../../components/AppImage";

import './profile.css';


const { Title } = Typography;
const { TabPane } = Tabs;


const Profile = ({ myProfile, history, match }) => {
    const dispatch = useDispatch();
    const profile = useSelector(selectProfileData);
    const authUser = useSelector(selectUser);
    const myProfileData = useSelector(selectMyProfile);
    const myIdeas = useSelector(selectMyIdeas);
    const [myIdeasLoading, setMyIdeasLoading] = useState(false);


    const [view, setView] = useState('ideas');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [ideaViewType, setIdeaViewType] = useState('public');

    const isMyProfile = () => {
        if (myProfile) {
            return true;
        }
        if (authUser?.profile_id == match?.params?.id) {
            return true;
        }
        return false;
    }

    const profileData = isMyProfile() ? myProfileData : profile;
    const isFollowing = profileData?.followers.some(item => item.id === authUser?.id);

    const getProfileData = () => {
        if (myProfile) {
            dispatch(getMyProfile());
        } else {
            dispatch(getMyProfile());
            dispatch(getProfile({ id: match.params.id }));
        }
    }

    const getMyProfileIdeas = async (ideaView) => {
        const params = {
            search: 0
        };
        if (ideaView === 'private') {
            params.search = 1;
        }
        setMyIdeasLoading(true);
        await dispatch(getMyIdeas(params));
        setMyIdeasLoading(false);
    }


    useEffect(() => {
        getProfileData();
        if (isMyProfile()) {
            getMyProfileIdeas(ideaViewType);
        }

    }, [match.params.id])


    const getUserName = () => {
        if (profileData?.first_name?.length || profileData?.last_name?.length) {
            return `${profileData.first_name} ${profileData.last_name}`
        } else if (profileData?.username) {
            return profileData.username;
        }
        return '';
    }

    const followUser = async () => {
        if (!isSubmitting) {
            setIsSubmitting(true);
            await dispatch(followProfile(profileData.id));
            setIsSubmitting(false);
            dispatch(getProfile(profileData.id));
        }
    }

    const unfollowUser = async () => {
        if (!isSubmitting) {
            setIsSubmitting(true);
            await dispatch(unfollowProfile(profileData.id));
            setIsSubmitting(false);
            dispatch(getProfile(profileData.id));
        }
    }

    const changeIdeaView = (updatedIdeaView) => {
        getMyProfileIdeas(updatedIdeaView);
        setIdeaViewType(updatedIdeaView);
    }

    return (
        <>
            <div className="page-container profile-page">
                <Row wrap={false} align="middle">
                    <Col flex="auto">
                        <div className="center-container">

                            <Card style={{ minHeight: 98 }}>
                                <Skeleton loading={!profileData} avatar active paragraph={{ rows: 0 }}>

                                    <Row>
                                        <Col xl={12} lg={24} md={24} sm={24} xs={24} >
                                            <Row wrap={false} align="middle">
                                                <Col flex="none" className="profile-image-container">
                                                    <AppImage
                                                        alt="user"
                                                        src={profileData?.avatar}
                                                        className="profile-image"
                                                    />
                                                </Col>
                                                <Col flex="auto" style={{ paddingLeft: 166 }}>
                                                    <Title level={3} className="capitalize no-margin">{getUserName()}</Title>
                                                    <p className="capitalize note no-margin fs-16">{profileData?.bio}</p>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xl={12} lg={24} md={24} sm={24} xs={24} >
                                            <div style={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'flex-end' }} className="fs-16">
                                                <span className="stats note pointer" onClick={() => setView('ideas')}>
                                                    <p className="no-margin"><b className="black">{profileData?.posts_count}</b> Ideas</p>
                                                </span>
                                                <span className="stats note divider">•</span>
                                                <span className="stats note"><b className="black">{profileData?.likes_received}</b> Likes</span>
                                                <span className="stats note divider">•</span>
                                                <span className="stats note pointer" onClick={() => setView('followers')}>
                                                    <p className="no-margin"><b className="black">{profileData?.followers_count}</b> Followers</p>
                                                </span>
                                                <span className="stats note divider">•</span>
                                                <span className="stats note pointer" onClick={() => setView('followings')}>
                                                    <p className="no-margin"><b className="black">{profileData?.followings?.length}</b> Following</p>
                                                </span>

                                                {isMyProfile() &&
                                                    <Link to="my-profile/settings"><Button type="primary" className="follow-btn rounded">
                                                        Edit Profile
                                                    </Button>
                                                    </Link>}

                                                {!isMyProfile() && isFollowing && <Button type="primary" className="follow-btn rounded" onClick={unfollowUser}>
                                                    {isSubmitting ? <Spin /> : 'Unfollow'}


                                                </Button>}
                                                {!isMyProfile() && !isFollowing && <Button type="primary" className="follow-btn rounded" onClick={followUser}>
                                                    {isSubmitting ? <Spin /> : 'Follow'}
                                                </Button>}

                                            </div>
                                        </Col>
                                    </Row>
                                </Skeleton>
                            </Card>
                            {view === 'ideas' && <>
                                {isMyProfile() ?
                                    <>
                                        <Tabs activeKey={ideaViewType} onChange={changeIdeaView}>
                                            <TabPane tab="Public" key="public">
                                            </TabPane>
                                            <TabPane tab="Private" key="private">

                                            </TabPane>
                                        </Tabs>
                                        {myIdeasLoading ? <Spin /> : myIdeas.map((idea, index) => <IdeaCard key={index} idea={idea} />)}
                                    </>
                                    :
                                    profileData?.posts?.map((idea, index) => <IdeaCard key={index} idea={idea} />)
                                }

                            </>}

                            {view === 'followers' && <>
                                <FriendList friends={profileData?.followers} title="Followers" />
                            </>}
                            {view === 'followings' && <>
                                <FriendList friends={profileData?.followings} title="Following" />
                            </>}
                        </div>
                    </Col>
                    <Col flex="none" className="leaderboard-container">
                        <div className="right-container fixed">
                            <LeaderBoard />
                        </div>
                    </Col>
                </Row>
            </div>
        </>

    )
}

export default withRouter(Profile);
