import React, { useState, useEffect } from "react";

import { Row, Col, Typography, Form, Input, Button, Upload, Modal, Checkbox, Dropdown, notification } from 'antd';

import close from '../../../assets/icons/close-round.png';
import uploadIcon from '../../../assets/icons/upload-outline.png';
import dropDownIcon from '../../../assets/icons/drop-down.png';
import publicIcon from '../../../assets/icons/public.png';
import userIcon from '../../../assets/icons/user.png';
import document from '../../../assets/icons/document.png';

import { useSelector, useDispatch } from "react-redux";
import { selectMyProfile } from '../profile/slice';
import { postIdeas, updateIdea } from '../home/thunk';

import AppImage from "../../../components/AppImage";

import './createNewIdea.css';

const { Title } = Typography;

export const VIEW_TYPE = {
    create: 'create',
    edit: 'edit',
    fork: 'fork',
}

const CreateNewIdea = ({ visible, onCancel, idea, viewType = VIEW_TYPE.create }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [formChanged, setFormedChanged] = useState(false);
    const myProfileData = useSelector(selectMyProfile);
    const [isPublicPost, setIsPublicPost] = useState(true);

    useEffect(() => {
        console.log("forcing form rerender on any field change");
    }, [formChanged]);

    useEffect(() => {
        if (idea?.id && visible) {
            const data = {
                title: idea.title,
                description: idea.description,
                ideas: []
            };
            for (let i = 1; i <= 10; i++) {
                const item = { title: '', showImageInput: false, showDescriptionInput: false, image: null, description: '' };
                if (idea[`sentence${i}`]) {
                    item.title = idea[`sentence${i}`];
                }
                if (idea[`explanation${i}`]) {
                    item.showDescriptionInput = true;
                    item.description = idea[`explanation${i}`];
                }
                data.ideas.push(item);
                // if (idea.image?.file) {
                //     data[`image${i}`] = idea.image?.file;
                // }
            }
            form.setFieldsValue({ ...data });
            setIsPublicPost(idea.access_level === 0);
        } else if (!idea) {
            setIsPublicPost(true);
        }
    }, [visible, idea]);

    const beforeUpload = (file) => {
        return false;
    }

    const getUserName = (data) => {
        if (data?.first_name?.length || data?.last_name?.length) {
            return `${data.first_name} ${data.last_name}`
        } else if (data?.username) {
            return data.username;
        }
        return '';
    }

    const onFinish = async (values) => {
        console.log('Received values of form: ', values);
        const data = {
            title: values.title,
            description: values.description,
            sentence1: '',
            explanation1: '',
            access_level: isPublicPost ? 0 : 1,
        }
        let i = 1;
        for (const idea of values.ideas) {
            data[`sentence${i}`] = idea.title;
            data[`explanation${i}`] = idea.description;
            if (idea.image?.file) {
                data[`image${i}`] = idea.image?.file;
            }
            i++;
        }
        const formData = new FormData();
        for (const key in data) {
            formData.set(key, data[key]);
        }
        if (viewType === VIEW_TYPE.edit) {
            _updateIdea(data);
        } else if (viewType === VIEW_TYPE.fork) {
            formData.set('forked_from', idea.id);
            createIdea(formData);
        } else {
            createIdea(formData);
        }

    };

    const createIdea = async (data) => {
        try {
            await dispatch(postIdeas(data));
            if (viewType === VIEW_TYPE.fork) {
                notification.success({
                    message: 'Success',
                    description: 'Successfully Forked Idea List.',
                });
            } else {
                notification.success({
                    message: 'Success',
                    description: 'New Idea List Created.',
                });
            }
            onCancel(true);
        } catch {
            notification.error({
                message: 'Error',
                description: 'Error while creating new idea list.',
            });
        }
    }

    const _updateIdea = async (data) => {
        try {
            await dispatch(updateIdea({ id: idea.id, data }));
            notification.success({
                message: 'Success',
                description: 'Updated Idea List.',
            });
            onCancel(true);
        } catch {
            notification.error({
                message: 'Error',
                description: 'Error while updating idea list.',
            });
        }
    }

    const submitForm = () => {
        form.submit();
    }

    const showImageInput = (key) => {
        return form.getFieldValue('ideas')[key].showImageInput.toString() === 'true';
    }

    const showDescriptionInput = (key) => {
        return form.getFieldValue('ideas')[key].showDescriptionInput.toString() === 'true';
    }

    const getTitle = () => {
        if (viewType === VIEW_TYPE.edit) {
            return 'update idea list'
        } else if (viewType === VIEW_TYPE.fork) {
            return 'edit fork'
        }
        return 'Create New Idea List';
    }

    const getSubmitButtonTitle = () => {
        if (viewType === VIEW_TYPE.fork) {
            return 'Post'
        }
        return 'Update';
    }

    return (
        <>
            <Modal
                onCancel={onCancel}
                visible={visible}
                width={'90vw'}
                closeIcon={<AppImage src={close} />}
                title={<Title level={3} className="capitalize no-margin">{getTitle()}</Title>}
                className="create-new-idea-modal" footer={
                    <Row className="action-container">
                        <Col span={12} className="user-detail" style={{ display: 'flex', alignItems: 'center' }}>
                            <AppImage src={myProfileData?.avatar} className="user-image" />
                            <span className="fs-20 user-name">{getUserName(myProfileData)}</span>
                        </Col>
                        <Col span={12} className="text-right">
                            <Dropdown overlay={
                                <div className='dropdown-action-container'>
                                    <Button type="secondary" icon={<AppImage src={!isPublicPost ? publicIcon : userIcon} className="icon mb-5" />} onClick={() => setIsPublicPost(!isPublicPost)} >
                                        <span>
                                            {!isPublicPost ? 'Public' : 'Private'}
                                        </span>
                                    </Button>
                                </div>
                            } placement="bottomRight" arrow
                                trigger={['click']}
                                overlayClassName="idea-access-control-dropdown" >
                                <Button type="secondary" icon={<AppImage src={isPublicPost ? publicIcon : userIcon} className="icon mb-5" />} >
                                    <span>
                                        {isPublicPost ? 'Public' : 'Private'}
                                        <AppImage src={dropDownIcon} className="icon" />
                                    </span>
                                </Button>
                            </Dropdown>
                            <Button type="primary" onClick={submitForm}>{getSubmitButtonTitle()}</Button>
                        </Col>

                    </Row>
                }>
                <Form
                    layout="vertical"
                    form={form}
                    className="create-new-idea-form"
                    initialValues={{
                        title: '', description: '', ideas: [
                            { title: '', showImageInput: false, showDescriptionInput: false, image: null, description: '' },
                            { title: '', showImageInput: false, showDescriptionInput: false, image: null, description: '' },
                            { title: '', showImageInput: false, showDescriptionInput: false, image: null, description: '' },
                            { title: '', showImageInput: false, showDescriptionInput: false, image: null, description: '' },
                            { title: '', showImageInput: false, showDescriptionInput: false, image: null, description: '' },
                            { title: '', showImageInput: false, showDescriptionInput: false, image: null, description: '' },
                            { title: '', showImageInput: false, showDescriptionInput: false, image: null, description: '' },
                            { title: '', showImageInput: false, showDescriptionInput: false, image: null, description: '' },
                            { title: '', showImageInput: false, showDescriptionInput: false, image: null, description: '' },
                            { title: '', showImageInput: false, showDescriptionInput: false, image: null, description: '' },
                        ]
                    }}
                    onValuesChange={(changedValue, allValues) => {
                        console.log(changedValue, allValues);
                        setFormedChanged(prev => !prev)
                    }}
                    onFinish={onFinish}
                >
                    <Form.Item name="title"
                        rules={[{ required: true, message: 'Title is required!' }]}>
                        <Input placeholder="Title" className="new-idea-input" />
                    </Form.Item>
                    <Form.Item name="description"
                        rules={[{ required: true, message: 'Description is required!' }]}>
                        <Input.TextArea placeholder="Description" className="new-idea-text-area" />
                    </Form.Item>
                    <Form.List name="ideas">
                        {(fields, { add, remove }) => (
                            <Row gutter={[16, 0]}>
                                {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                                    <Col lg={12} md={24}>
                                        <Row wrap={false}>
                                            <Col flex="auto">
                                                <Title level={3} className="capitalize note fw-500">Idea # {index + 1}</Title>
                                            </Col>
                                            <Col flex="none">
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'showImageInput']}
                                                    fieldKey={[fieldKey, 'showImageInput']}
                                                    valuePropName="checked">
                                                    <Checkbox className="checkbox-icon-hidden"><AppImage src={uploadIcon} className="icon" /></Checkbox>
                                                </Form.Item>
                                            </Col>
                                            <Col flex="none">
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'showDescriptionInput']}
                                                    fieldKey={[fieldKey, 'showDescriptionInput']}
                                                    valuePropName="checked">
                                                    <Checkbox className="checkbox-icon-hidden"><AppImage src={document} className="icon" /></Checkbox>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row wrap={false} gutter={[16, 24]}>
                                            <Col flex="auto">
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'title']}
                                                    fieldKey={[fieldKey, 'title']}
                                                    rules={[{ required: true, message: 'Title is required!' }]}>
                                                    <Input placeholder={`Idea # ${index + 1} Title`} className="new-idea-input" />
                                                </Form.Item>
                                            </Col>
                                            {showImageInput(key) && <Col flex="none">
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'image']}
                                                    fieldKey={[fieldKey, 'image']}>
                                                    <Upload
                                                        listType="picture-card"
                                                        className="idea-image-uploader"
                                                        showUploadList={false}
                                                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                        beforeUpload={beforeUpload}
                                                    >
                                                        <div className="idea-image-uploader-content">
                                                            <span className="note">Upload Image </span>
                                                            <AppImage src={uploadIcon} className="icon" />
                                                        </div>
                                                    </Upload>
                                                </Form.Item>
                                            </Col>}
                                        </Row>
                                        {showDescriptionInput(key) && <Form.Item
                                            {...restField}
                                            name={[name, 'description']}
                                            fieldKey={[fieldKey, 'description']}>
                                            <Input.TextArea placeholder="Write Description (optional)" className="new-idea-text-area" />
                                        </Form.Item>}

                                    </Col>
                                ))}
                                {form.getFieldValue('ideas').length < 10 &&
                                    < Form.Item >
                                        <Button className="add-more-ideas-btn" onClick={() => add()} block >
                                            <span className="primary fs-16">Add more ideas</span>
                                        </Button>
                                    </Form.Item>
                                }
                            </Row>
                        )}
                    </Form.List>
                    <Form.Item>
                        <Input placeholder="Tags" className="new-idea-input" />
                    </Form.Item>
                </Form>
            </Modal>
        </>

    )
}

export default CreateNewIdea;
