import { createSlice, isPending, isRejected } from "@reduxjs/toolkit";

import {
    searchIdeas,
    searchProfiles
} from "./thunk";

const thunks = [searchIdeas, searchProfiles];

const initialState = {
    status: "idle",
    errorMessage: '',
    ideas: [],
    profiles: []
};

export const slice = createSlice({
    name: "search",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(searchIdeas.fulfilled, (state, action) => {
                state.status = "idle";
                state.ideas = action?.payload;
            })
            .addCase(searchProfiles.fulfilled, (state, action) => {
                state.status = "idle";
                state.profiles = action?.payload;
            })
            .addMatcher(isPending(...thunks), (state) => {
                state.status = "loading";
            })
            .addMatcher(isRejected(...thunks), (state, action) => {
                state.status = "failed";
                state.errorMessage = action?.error?.message;
            });
    },
});

export const selectStatus = (state) => state.search.status === "loading";
export const selectIdeas = (state) => state.search.ideas;
export const selectProfiles = (state) => state.search.profiles;


export default slice.reducer;
