import React, { useEffect, useState } from "react";


import { Row, Col, Card, Typography, Input, Spin } from 'antd';
import LeaderBoard from "../leaderBoard/leaderBoard";
import ChatBubble from "./chatBubble";

import edit from '../../../assets/icons/edit.png';
import online from '../../../assets/icons/online.png';
import send from '../../../assets/icons/send.png';

import { useDispatch, useSelector } from "react-redux";
import { selectUsers, selectInboxData, } from './slice';
import { getUsers, getInbox, startConversation, sendMessage } from './thunk';
import * as moment from 'moment';
import { withRouter } from 'react-router-dom';

import NewMessage from "./newMessage";

import AppImage from "../../../components/AppImage";


import './messages.css';

const { Title } = Typography;

const Messages = ({ history }) => {

    const dispatch = useDispatch();
    const users = useSelector(selectUsers);
    const inboxData = useSelector(selectInboxData);
    const [selectedUser, setSelectedUser] = useState('');
    const [newMessage, setNewMessage] = useState('');
    const [sendingMessage, setSendingMessage] = useState(false);
    const [showNewMessageModal, setShowNewMessageModal] = useState(false);


    useEffect(async () => {
        await dispatch(getUsers());
        dispatch(getInbox());
    }, [])

    useEffect(async () => {
        if (selectedUser) {
            setNewMessage('');
            await dispatch(startConversation(selectedUser));
            dispatch(getInbox());
        }
    }, [selectedUser])

    const handleKeyPress = (e) => {
        const key = e.keyCode || e.which;
        if (key === 13) {
            addNewMessage();
        }
    }

    const addNewMessage = async () => {
        setSendingMessage(true);
        const data = {
            to_user: selectedUser,
            body: newMessage
        }
        await dispatch(sendMessage(data));
        await dispatch(getInbox());
        setNewMessage('');
        setSendingMessage(false);
    }

    const renderChatBubble = (data, index) => {
        let sentByUser = false;
        let avatar = data?.recipient_details?.avatar;
        if (data?.sender_details?.username !== selectedUser) {
            sentByUser = true;
        }

        return (
            <ChatBubble
                key={index}
                date={data.date}
                body={data.body}
                sentByMe={sentByUser}
                avatar={avatar}
                profileId={data?.sender_details?.profile_id} />
        );
    }

    const startNewMessage = (username) => {
        setSelectedUser(username);
        setShowNewMessageModal(false);
    }

    const redirectToProfileDetails = () => {
        const data = inboxData?.messages_data?.find(item => item?.user?.username === selectedUser);
        if (data?.user?.profile_id) {
            history.push(`/profile/${data.user.profile_id}`);
        }

    }

    return (
        <>
            <NewMessage
                users={users}
                visible={showNewMessageModal}
                onCancel={() => setShowNewMessageModal(false)}
                startNewMessage={startNewMessage} />
            <div className="page-container messages-page">
                <Row wrap={false} align="middle">
                    <Col flex="none" style={{
                        width: 293,
                    }}>
                        <div className="left-container fixed user-list">
                            <Card style={{
                                width: 293,
                                height: '74vh',
                            }} >
                                <Row wrap={false} align="middle" className="title-container">
                                    <Col flex="auto">
                                        <Title level={4} className="capitalize">messages</Title>
                                    </Col>
                                    <Col flex="none" className="mb-5">
                                        <AppImage src={edit} className="icon pointer" onClick={() => setShowNewMessageModal(true)} />
                                    </Col>
                                </Row>
                                <hr className="no-margin" />
                                <div className="user-list-container">
                                    {!inboxData &&
                                        <div className="loader-container">
                                            <Spin />
                                        </div>
                                    }
                                    {inboxData?.messages_data?.map((user, index) => <>
                                        <div key={index} className={"user-container pointer"} onClick={() => setSelectedUser(user.user.username)} >
                                            {user.user.username === selectedUser && <div className="active" />}
                                            <Row wrap={false} align="middle">
                                                <Col flex="none" className="mb-5">
                                                    <AppImage src={user.user.avatar} className="user-image" />
                                                </Col>
                                                <Col flex="auto">
                                                    <p className="fs-18 no-margin">{user.user.username}</p>
                                                </Col>
                                                <Col flex="none" className="mb-5">
                                                    <p className="capitalize note fs-12 date">{moment(user.last).format('MMM DD')}</p>
                                                </Col>
                                            </Row>
                                        </div>
                                        {inboxData?.messages_data?.length !== (index + 1) && <hr className="no-margin" />}
                                    </>)}
                                </div>

                            </Card>
                        </div>
                    </Col>
                    <Col flex="auto">
                        {selectedUser &&
                            <div className="center-container">
                                <Card style={{
                                    height: '74vh',
                                    marginTop: 5
                                }} className="messages-card" loading={!inboxData}>
                                    {inboxData &&
                                        <>
                                            <div className="messages-title-container">
                                                <Title level={5} className="fw-500 fs-18 mb-25 pointer" onClick={redirectToProfileDetails}>{selectedUser}
                                                    {/* <span className='status-container'>
                                                        <AppImage src={online} className="icon" />
                                                        <span className="status note">Online</span>
                                                    </span> */}
                                                </Title>
                                                <hr />
                                            </div>
                                            <div className="messages-container">
                                                {inboxData?.directs?.map((chat, index) => renderChatBubble(chat, index))}

                                            </div>
                                            <Input
                                                disabled={sendingMessage}
                                                placeholder="Write a message..."
                                                className="message-input"
                                                value={newMessage}
                                                onChange={(e) => setNewMessage(e.target.value)}
                                                onKeyPress={handleKeyPress}
                                                suffix={sendingMessage ? <Spin /> :
                                                    <AppImage src={send} className="pointer" onClick={addNewMessage} />
                                                } />
                                        </>
                                    }
                                </Card>
                            </div>
                        }
                    </Col>
                    <Col flex="none" style={{
                        width: 293,
                    }}>
                        <div className="right-container fixed">
                            <LeaderBoard />
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default withRouter(Messages);
