import React, { useEffect, useState } from "react";

import { Row, Col, Card, Typography, Button, Avatar, Input, Spin } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';


import IdeaCard from '../home/ideaCard';
import LeaderBoard from "../leaderBoard/leaderBoard";
import back from '../../../assets/icons/back.png';
import send from '../../../assets/icons/send.png';
import user1 from '../../../assets/images/user1.png';
import user2 from '../../../assets/images/user2.png';

import { useDispatch, useSelector } from "react-redux";
import { selectComments, selectStatus, selectIdeaDetails } from './slice';
import { getComments, addComment, clearComments, getIdeaDetails, toggleCommentsReply } from './thunk';
import { selectMyProfile } from '../profile/slice';

import AppImage from "../../../components/AppImage";

import * as moment from 'moment';


import './ideaDetails.css';


const { Title } = Typography;

const IdeaDetails = ({ history, ...props }) => {

    const dispatch = useDispatch();
    const idea = useSelector(selectIdeaDetails);
    const comments = useSelector(selectComments);
    const isLoading = useSelector(selectStatus);
    const myProfile = useSelector(selectMyProfile);
    const [newComment, setNewComment] = useState('');
    const [newReply, setNewReply] = useState('');
    const [commentToBeRepliedId, setCommentToBeRepliedId] = useState(0);
    const [commentsCount, setCommentsCount] = useState(0);
    const ideaId = props?.match?.params?.id;

    useEffect(async () => {
        await dispatch(clearComments());
        dispatch(getIdeaDetails(ideaId));
        dispatch(getComments(ideaId));
    }, [props?.match?.params?.id])

    useEffect(() => {
        if (idea) {
            setCommentsCount(idea.comments_count)
        }
    }, idea)

    const goBack = () => {
        history.goBack();
    }

    const getUserName = (user) => {
        if (user?.first_name?.length || user?.last_name?.length) {
            return `${user.first_name} ${user.last_name}`
        } else if (user?.username) {
            return user.username;
        }
        return '';
    }

    const handleKeyPress = (e) => {
        const key = e.keyCode || e.which;
        if (key === 13) {
            addNewComment();
        }
    }

    const addNewComment = async () => {
        if (newComment?.trim().length) {
            try {
                await dispatch(addComment({ post: ideaId, body: newComment.trim() }));
                dispatch(getComments(ideaId));
                setNewComment('');
                setCommentsCount(commentsCount + 1);
            } catch (err) {
                console.log(err);
            }
        }
    }

    const handleKeyPressReply = (e) => {
        const key = e.keyCode || e.which;
        if (key === 13) {
            addNewReply();
        }
    }

    const addNewReply = async () => {
        if (newReply?.trim().length) {
            try {
                await dispatch(addComment({ post: ideaId, body: newReply.trim(), parent_comment: commentToBeRepliedId }));
                dispatch(getComments(ideaId));
                setNewReply('');
                setCommentsCount(commentsCount + 1);
            } catch (err) {
                console.log(err);
            }
        }
    }


    return (
        <>

            <Row className="page-container home-page" style={{ marginTop: 50 }}>
                <Col xl={{ span: 14, offset: 2 }} sm={{ span: 14, offset: 2 }} style={{
                    padding: 20,
                }}>

                    <Card className="back-title-container pointer" onClick={goBack}>
                        <Row>
                            <Col style={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: '0px 20px'
                            }}>
                                <AppImage src={back} className="back-icon" />
                            </Col>
                            <Col>
                                <Title level={4} className="capitalize inline">idea post</Title>
                            </Col>
                        </Row>
                    </Card>


                    <IdeaCard hideLink={true} idea={idea} showCustomCommentCount={true} commentsCount={commentsCount} />

                    <Card style={{ marginTop: 20 }} className="comments-container">
                        <Title level={5} className="capitalize fs-18">All comments</Title>
                        <hr className="mt-20" />
                        {comments.map((item, index) =>
                            <Row key={index} className="comment">
                                <Col span={3} className="text-center">
                                    <AppImage className="user-image" src={item.avatar} />
                                </Col>
                                <Col span={21}>
                                    <p className="user-name fs-16"><b className="pointer" onClick={() => history.push(`/profile/${item.profile_id}`)} >{item.user}</b> <span className="note sm-font">{moment(item.created).fromNow()}</span> </p>
                                    <p className="note fs-14">{item.body}</p>
                                    <span className="note fs-12 pointer ml-10" onClick={() => setCommentToBeRepliedId(item.id)}>Reply</span>
                                    {item.replies?.length > 0 ?
                                        <span className="note fs-12 pointer ml-10" onClick={() => dispatch(toggleCommentsReply(item.id))}> {item.replies?.length} {item.replies?.length === 1 ? 'Reply' : 'Replies'}</span>
                                        : null}

                                    {item.showReplies && item.replies?.map((reply, replyIndex) =>
                                        <Row key={replyIndex} className="comment reply">
                                            <Col span={3} className="text-center">
                                                <AppImage className="user-image" src={reply.avatar} />
                                            </Col>
                                            <Col span={21}>
                                                <p className="user-name fs-16"><b className="pointer" onClick={() => history.push(`/profile/${reply.profile_id}`)}>{reply.user}</b> <span className="note sm-font">{moment(reply.created).fromNow()}</span> </p>
                                                <p className="note fs-14">{reply.body}</p>

                                                {item.replies?.length > (replyIndex + 1) && <hr className="mt-20" />}

                                            </Col>
                                        </Row>
                                    )}

                                    {item.id && commentToBeRepliedId === item.id &&
                                        <Row className="comment">
                                            <Col span={3} className="text-center">
                                                <AppImage className="user-image" src={myProfile?.avatar} />
                                            </Col>
                                            <Col span={21}>
                                                <Input
                                                    placeholder="Write a reply..."
                                                    className="comment-input"
                                                    suffix={isLoading ? <Spin /> : <AppImage src={send} className="send-btn" onClick={addNewReply} />}
                                                    disabled={isLoading}
                                                    onKeyPress={handleKeyPressReply}
                                                    value={newReply}
                                                    onChange={(e) => setNewReply(e.target.value)}
                                                />
                                            </Col>
                                        </Row>
                                    }

                                    {comments.length > (index + 1) && <hr className="mt-20" />}

                                </Col>
                            </Row>)}

                        <hr className="mt-20" />
                        <Row className="comment">
                            <Col span={3} className="text-center">
                                <AppImage className="user-image" src={myProfile?.avatar} />
                            </Col>
                            <Col span={21}>
                                <Input
                                    placeholder="Write a comment..."
                                    className="comment-input"
                                    suffix={isLoading ? <Spin /> : <AppImage src={send} className="send-btn" onClick={addNewComment} />}
                                    disabled={isLoading}
                                    onKeyPress={handleKeyPress}
                                    value={newComment}
                                    onChange={(e) => setNewComment(e.target.value)}
                                />
                            </Col>
                        </Row>
                    </Card>

                </Col>
                <Col xl={{ span: 6 }} style={{
                    // padding: 20,
                }}>
                    <LeaderBoard />
                </Col>

            </Row>
        </>

    )
}

export default withRouter(IdeaDetails);
