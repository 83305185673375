import { createAsyncThunk } from "@reduxjs/toolkit";

import {
    getProfile as getProfileApi,
    getMyProfile as getMyProfileApi,
    updateMyProfile as updateMyProfileApi,
    followProfile as followProfileApi,
    unfollowProfile as unfollowProfileApi,
    getMyIdeas as getMyIdeasApi,
} from "./service";

export const getProfile = createAsyncThunk("profile/get", async ({ id, params = {} }) => {
    const response = await getProfileApi(id, params);
    return response;
});

export const getMyProfile = createAsyncThunk("profile/my/get", async (params = {}) => {
    const response = await getMyProfileApi(params);
    return response;
});

export const updateMyProfile = createAsyncThunk("profile/my/update", async (data) => {
    const response = await updateMyProfileApi(data);
    return response;
});


export const followProfile = createAsyncThunk("profile/follow", async (id) => {
    const response = await followProfileApi(id);
    return response;
});

export const unfollowProfile = createAsyncThunk("profile/unfollow", async (id) => {
    const response = await unfollowProfileApi(id);
    return response;
});

export const getMyIdeas = createAsyncThunk("profile/my-posts", async (params = {}) => {
    const response = await getMyIdeasApi(params);
    return response;
});
