import { createAsyncThunk } from "@reduxjs/toolkit";

import {
    login as loginApi,
    register as registerApi,
} from "./service";

export const login = createAsyncThunk("account/login", async (data) => {
    const response = await loginApi(data);
    return response;
});

export const setData = createAsyncThunk("account/set-data", async (data) => {
    return data;
});

export const logout = createAsyncThunk("account/logout", async () => {
    return null;
});

export const register = createAsyncThunk("account/register", async (data) => {
    const response = await registerApi(data);
    console.log(response);
    return response;
});