import { createSlice, isPending, isRejected } from "@reduxjs/toolkit";

import {
    login,
    setData,
    logout,
    register,
} from "./thunk";

const thunks = [login, setData, logout];

const initialState = {
    status: "loading",
    errorMessage: '',
    user: null,
    token: null,
};

export const slice = createSlice({
    name: "auth",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(login.fulfilled, (state, action) => {
                state.status = "idle";
                state.user = action?.payload?.user;
                state.token = action?.payload?.token;
                localStorage.setItem('@user', JSON.stringify({ user: state.user, token: state.token }));
            })
            .addCase(register.fulfilled, (state, action) => {
                state.status = "idle";
            })
            .addCase(logout.fulfilled, (state, action) => {
                state.status = "idle";
                state.user = null;
                state.token = null;
                localStorage.removeItem('@user');
                localStorage.removeItem('@token');
            })
            .addCase(setData.fulfilled, (state, action) => {
                state.status = "idle";
                state.user = action?.payload?.user;
                state.token = action?.payload?.token;
            })
            .addMatcher(isPending(...thunks), (state) => {
                state.status = "loading";
            })
            .addMatcher(isRejected(...thunks), (state, action) => {
                state.status = "failed";
                state.errorMessage = action?.error?.message;
            });
    },
});

export const selectStatus = (state) => state.auth.status === "loading";
export const selectUser = (state) => state.auth.user;
export const selectToken = (state) => state.auth.token;


export default slice.reducer;
