import React, { useEffect } from "react";

import { Row, Col, Card, Typography } from 'antd';
import { Link } from 'react-router-dom';

import CreateNewIdeaCard from './createNewIdeaCard';
import IdeaCard from './ideaCard';
import LeaderBoard from "../leaderBoard/leaderBoard";
import user1 from '../../../assets/images/user1.png';

import { useDispatch, useSelector } from "react-redux";
import { selectIdeas } from './slice';
import { getIdeas } from './thunk';
import { selectMyProfile } from '../profile/slice';
import { getMyProfile } from '../profile/thunk';

import AppImage from "../../../components/AppImage";

import './home.css';


const { Title } = Typography;

const Home = () => {
    const dispatch = useDispatch();
    const ideas = useSelector(selectIdeas);
    const myProfile = useSelector(selectMyProfile);

    useEffect(() => {
        dispatch(getMyProfile());
        dispatch(getIdeas());
    }, [])


    const getUserName = (data) => {
        if (data?.first_name?.length || data?.last_name?.length) {
            return `${data.first_name} ${data.last_name}`
        } else if (data?.username) {
            return data.username;
        }
        return '';
    }

    const getAllIdeas = () => {
        dispatch(getIdeas());
    }

    return (
        <>
            <div className="page-container home-page">
                <Row wrap={false} align="center">
                    <Col flex="none" style={{
                        width: 237,
                    }} className="my-profile-container">
                        <div className="left-container fixed">
                            <Link to="my-profile">
                                <Card style={{
                                    textAlign: 'center',
                                    width: 237,
                                    height: 226,
                                }} className="pointer">
                                    {myProfile?.avatar &&
                                        <AppImage
                                            alt="user"
                                            src={myProfile?.avatar}
                                            style={{
                                                height: 94,
                                                width: 94,
                                                marginBottom: 20
                                            }}
                                            className="user-image"
                                        />
                                    }
                                    <Title level={3} className="capitalize no-margin">{getUserName(myProfile)}</Title>
                                    <p className="capitalize note no-margin fs-16">{myProfile?.bio}</p>

                                </Card>
                            </Link>

                            <Card style={{
                                textAlign: 'center',
                                marginTop: 20
                            }}>
                                <Row>
                                    <Col span={12} style={{
                                        borderRight: '1px solid #ccc'
                                    }}>
                                        <Title level={3} className="capitalize no-margin">{myProfile?.posts_count}</Title>
                                        <p className="capitalize note no-margin fs-16">Ideas</p>
                                    </Col>
                                    <Col span={12}>
                                        <Title level={3} className="capitalize no-margin">{myProfile?.likes_received}</Title>
                                        <p className="capitalize note no-margin fs-16">Likes</p>
                                    </Col>
                                </Row>

                            </Card>
                        </div>
                    </Col>
                    <Col flex="none" className="ideas-list-container">
                        <div className="center-container">

                            {/* <CreateNewIdeaCard /> */}
                            {ideas.map((idea, index) => <IdeaCard key={index} idea={idea} getAllIdeas={getAllIdeas} />)}

                        </div>
                    </Col>
                    <Col flex="none" className="leaderboard-container">
                        <div className="right-container fixed">
                            <LeaderBoard />
                        </div>
                    </Col>
                </Row>
            </div>
        </>

    )
}

export default Home;
