import { createAsyncThunk } from "@reduxjs/toolkit";

import {
    getLeaderboardIdeas as getLeaderboardIdeasApi,
    getUserLeaderboard as getUserLeaderboardApi,
} from "./service";

export const getLeaderboardIdeas = createAsyncThunk("leaderboard/posts", async (query = {}) => {
    const response = await getLeaderboardIdeasApi(query);
    return response;
});


export const getUserLeaderboard = createAsyncThunk("leaderboard/posts/users", async (query = {}) => {
    const response = await getUserLeaderboardApi(query);
    return response;
});

