import { post, get, put, del } from '../../../utils/httpService';

const SERVICE_URLS = {
  getComments: (id) => `/posts/details/${id}/comments/`,
  addComment: () => `/posts/details/new-comment/`,
  getIdeaDetails: (id) => `/posts/details/${id}`,
};

export const getComments = (id) => get(SERVICE_URLS.getComments(id));
export const addComment = (data) => post(SERVICE_URLS.addComment(), data);
export const getIdeaDetails = (id) => get(SERVICE_URLS.getIdeaDetails(id));