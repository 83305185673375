import React, { useState, useEffect } from "react";

import { Row, Col, Card, Typography, Avatar, Spin, notification, Image, Dropdown, Button, Modal, Space } from 'antd';
import { LikeOutlined,  FacebookFilled, TwitterSquareFilled, CopyFilled  } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import user2 from '../../../assets/images/user2.png';
import dislike from '../../../assets/icons/dislike.png';
import share from '../../../assets/icons/share.png';
import fork from '../../../assets/icons/fork.png';
import menuDots from '../../../assets/icons/menu-dots.png';
import ideaImage from '../../../assets/images/idea.png';

import { useSelector, useDispatch } from "react-redux";
import { selectUser } from '../../auth/slice';
import { selectMyProfile } from '../profile/slice';
import { likeIdea, postIdeas, deleteIdea } from './thunk';

import CreateNewIdea, { VIEW_TYPE } from '../CreateNewIdea/createNewIdea';

import AppImage from "../../../components/AppImage";

import * as moment from 'moment';




import './ideaCard.css';

const { Title, Text } = Typography;
const { Meta } = Card;

const debounce = (func, timeout = 300) => {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
}



const IdeaCard = ({ idea, history, showCustomCommentCount, commentsCount, getAllIdeas }) => {
    const dispatch = useDispatch();
    const authUser = useSelector(selectUser);
    const myProfile = useSelector(selectMyProfile);
    const [likedByUser, setLikedByUser] = useState(false);
    const [likedCount, setLikedCount] = useState(0);
    const [forkCount, setForkCount] = useState(0);
    const [isForkSubmitting, setForkSubmitting] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [showCreateIdeaModal, setCreateIdeaModal] = useState(false);
    const [createIdeaViewType, setCreateIdeaViewType] = useState(VIEW_TYPE.edit);

    const items = [];

    if (idea) {
        for (let i = 1; i <= 10; i++) {
            if (idea[`sentence${i}`]) {
                items.push({
                    sentence: idea[`sentence${i}`],
                    explanation: idea[`explanation${i}`],
                    image: idea[`image${i}`],
                });
            }
        }
    }

    const getIdeaPostDiff = () => {
        return moment(idea?.created).fromNow()
    }

    useEffect(() => {
        if (myProfile?.id && idea?.liked?.indexOf(myProfile?.id) >= 0) {
            setLikedByUser(true);
        }

    }, [idea, myProfile])

    useEffect(() => {
        if (idea) {
            setLikedCount(idea.likes_count);
            setForkCount(idea.fork_count);
        }

    }, [idea])


    const likeIdeaByUser = debounce(async () => {
        if (idea?.id) {
            await dispatch(likeIdea(idea.id));
            setLikedCount(likedByUser ? likedCount - 1 : likedCount + 1);
            setLikedByUser(!likedByUser);

        }
    }, 200)

    const forkIdea = debounce(async () => {
        if (idea?.id) {
            setCreateIdeaViewType(VIEW_TYPE.fork);
            setCreateIdeaModal(true);
            // setForkSubmitting(true);
            // // const newIdea = {
            // //     title: idea.title,
            // //     description: idea.description,
            // //     access_level: idea.access_level,
            // //     sentence1: idea.sentence1,
            // //     sentence2: idea.sentence2,
            // //     sentence3: idea.sentence3,
            // //     sentence4: idea.sentence4,
            // //     sentence5: idea.sentence5,
            // //     sentence6: idea.sentence6,
            // //     sentence7: idea.sentence7,
            // //     sentence8: idea.sentence8,
            // //     sentence9: idea.sentence9,
            // //     sentence10: idea.sentence10,
            // //     explanation1: idea.explanation1,
            // //     explanation3: idea.explanation3,
            // //     explanation2: idea.explanation2,
            // //     explanation4: idea.explanation4,
            // //     explanation5: idea.explanation5,
            // //     explanation6: idea.explanation6,
            // //     explanation7: idea.explanation7,
            // //     explanation8: idea.explanation8,
            // //     explanation9: idea.explanation9,
            // //     explanation10: idea.explanation10,
            // //     forked_from: idea.id
            // // }
            // await dispatch(postIdeas(newIdea));
            // setForkSubmitting(false);
            // setForkCount(forkCount + 1);
            // notification.success({
            //     message: 'Success',
            //     description: 'Successfully Forked Idea List.',
            // });

        }
    }, 200)

    const showAuthorProfile = () => {
        history.push(`/profile/${idea?.author_id}`)
    }

    const sharePost = () => {
        const link = `${window.location.origin}/idea/${idea.id}`;
        if (navigator.clipboard && window.isSecureContext) {
            navigator.clipboard.writeText(link);
        } else {
            let textArea = document.createElement("textarea");
            textArea.value = link;
            // make the textarea out of viewport
            textArea.style.position = "fixed";
            textArea.style.left = "-999999px";
            textArea.style.top = "-999999px";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            document.execCommand('copy')
            textArea.remove();
        }

        notification.success({
            message: 'Success',
            description: 'Link copied to your clipboard.',
        });
    }


    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
        console.log('show')
    };


    const handleCancel = () => {
        setIsModalVisible(false);
        console.log('cancel')
    };

    const redirectToIdeaDetails = () => {
        history.push(`/idea/${idea.id}`);
    }

    const deleteIdeaList = async () => {
        if (!isDeleting) {
            setIsDeleting(true);
            try {
                await dispatch(deleteIdea(idea.id));
                notification.success({
                    message: 'Success',
                    description: 'Idea list deleted successfully.',
                });
                setIsDeleting(false);
                if (getAllIdeas) {
                    getAllIdeas();
                } else {
                    history.goBack();
                }

            }
            catch (err) {
                setIsDeleting(false);
                notification.error({
                    message: 'Error',
                    description: 'Error while deleing idea list.',
                });
            }

        }

    }

    const hideEditModal = (afterSave = false) => {
        if (afterSave === true) {
            if (createIdeaViewType === VIEW_TYPE.fork) {
                setForkCount(forkCount + 1);
            }
        }
        setCreateIdeaModal(false);
    }

    const renderDescription = (description = '') => {
        const subTexts = []
        for (let subText of description.split(' ')) {
            if (subText.indexOf('https://') === 0 || subText.indexOf('http://') === 0) {
                subTexts.push(<a target='_blank' href={subText}>{subText}</a>);
            } else {
                subTexts.push(`${subText} `);
            }
        }
        return subTexts;

        // return description;
    }

    return (<Card className="idea-card" loading={!idea} style={{
        marginTop: 20
    }}>
        {idea && <>
            <CreateNewIdea
                idea={idea}
                visible={showCreateIdeaModal}
                onCancel={hideEditModal}
                viewType={createIdeaViewType}
            />
            <Row className="idea-card-header">
                <Col span={20}>
                    <Meta
                        avatar={<Avatar className="pointer" src={idea?.user_image} onClick={showAuthorProfile} />}
                        title={
                            <div>
                                <span onClick={showAuthorProfile} className="pointer" style={{ padding: 20, paddingLeft: 0 }} > {idea.author_name} <span className="note">{idea.author}</span></span>
                                {/* <span className="primary pointer fs-12" style={{ padding: 20, paddingLeft: 0 }} onClick={() => console.log("Follow")} >Follow</span> */}
                            </div>
                        }
                        description={getIdeaPostDiff()}
                    />
                </Col>
                <Col span={4} className="text-right">
                    {idea?.author_id === myProfile?.id ?

                        <Dropdown overlay={
                            <>
                                <Card style={{ width: 202 }} className="profile-dropdown">
                                    <div className="pointer" onClick={() => setCreateIdeaModal(true)}>
                                        <p className="menu-item capitalize fs-16">
                                            edit idea list
                                        </p>
                                    </div>
                                    <hr />
                                    <div className="pointer" onClick={deleteIdeaList}>
                                        {isDeleting ? <Spin /> : <p className="menu-item mt-10 capitalize fs-16">
                                            delete idea list
                                        </p>}


                                    </div>
                                </Card>
                            </>
                        } placement="bottomRight" arrow
                            trigger={['click']} >
                            <AppImage src={menuDots} className="menu-icon pointer" />
                        </Dropdown>
                        : null}
                </Col>
            </Row>
            <hr />
            <div className="idea-card-body">
                <Title level={5} className="fs-20">{idea.title}</Title>

                {idea.image && <Image src={idea.image} className="idea-image" />}

                <p className="note fs-16">{renderDescription(idea.description)}</p>

                {items.map((item, index) =>
                    <div key={index}>
                        <Title level={5} className="capitalize fs-20"><span className="note">{index + 1}:</span> {item.sentence}</Title>

                        {item.image && <>
                            <Image src={item.image} className="idea-image" />
                            <div style={{ marginBottom: 28 }} />
                        </>}


                        <p className="note fs-16">{renderDescription(item.explanation)}</p>

                        {items.length !== (index + 1) && <hr />}
                        <div style={{ marginBottom: 25 }} />
                    </div>
                )}
                {idea.tags?.length > 0 &&
                    <p className="tags fs-16">
                        <b>Tags: </b>
                        <span className="primary">{idea.tags?.join(", ")}</span>
                    </p>
                }
            </div>
            <hr />
            <div className="idea-card-body">
                <Row className="idea-card-footer">
                    <Col span={16}>
                        <span className="action pointer" onClick={forkIdea}>
                            <AppImage src={fork} className="icon" />
                            {isForkSubmitting ? <Spin /> : <>
                                <span className="count fs-18"><b>{forkCount}</b></span>
                                <span className="note fs-18">Forks</span>
                            </>}

                        </span>

                        <span className="action pointer" onClick={likeIdeaByUser}>
                            {/* <AppImage src={dislike} className="icon" /> */}
                            <LikeOutlined className={likedByUser ? "primary" : "like-icon"} style={{ fontSize: 22, marginRight: 10, marginBottom: 5 }} />
                            <span className="count fs-18"><b>{likedCount}</b></span>
                            <span className="note fs-18">Like</span>
                        </span>

                        <span className="action pointer"  onClick={showModal}>
                            <AppImage src={share} className="icon" />
                            <span className="note fs-18">Share</span>
                        </span>

                    </Col>
                    <Col span={8} className="text-right fs-16 pointer" onClick={redirectToIdeaDetails}>
                        <span className="stats note"><b>{idea.views}</b> Views</span>
                        <span className="stats note">•</span>
                        {showCustomCommentCount ? <span className="stats note"><b>{commentsCount}</b> Comments</span>
                            : <span className="stats note"><b>{idea.comments_count}</b> Comments</span>}

                    </Col>
                </Row>
                <Modal title="Share Post" visible={isModalVisible}
                    onCancel={handleCancel}
                    maskClosable={true}
                    footer={null}

                >
                    <div className="modal">


                        <Space direction="vertical" size={2}>
                            <Title level={5} className="primary">Share post on social media platform to get more view & likes</Title>
                        </Space>
                        <Row justify="center" align="center" gutter={[12, 12]}>
                            <Col span={6} align="center" className="pointer">
                                <Space direction="vertical">
                                    <Button size="large" type="default" shape="circle" className="btn icon" icon={<FacebookFilled />} />
                                    <Title level={5}>Facebook</Title>
                                </Space>
                            </Col>
                            <Col span={6} align="center" className="pointer">
                                <Space direction="vertical">
                                    <Button size="large" type="default" shape="circle" className="btn icon" icon={<TwitterSquareFilled />} />
                                    <Title level={5}>Twitter</Title>
                                </Space>
                            </Col>
                            <Col span={6} align="center" className="pointer" onClick={sharePost}>
                                <Space direction="vertical">
                                    <Button size="large" type="default" shape="circle" className="btn icon" icon={<CopyFilled />} />
                                    <Title level={5}>Copy Link</Title>
                                </Space>
                            </Col>
                        </Row>
                    </div>
                </Modal>
            </div>
        </>
        }
    </Card>);

}

export default withRouter(IdeaCard);
