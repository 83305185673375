import React, { useState, useEffect } from "react";

import { Row, Col, Typography, Form, Input, Button, Upload, Modal, Checkbox, Dropdown, notification } from 'antd';

import close from '../../../assets/icons/close-round.png';
import user1 from '../../../assets/images/user1.png';

import AppImage from "../../../components/AppImage";


const { Title } = Typography;


const NewMessage = ({ visible, onCancel, users, startNewMessage }) => {
    const [selectedUser, setSelectedUser] = useState('');
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        if (visible) {
            setSelectedUser('');
            setSearchText('');
        }
    }, [visible])

    return (
        <>
            <Modal
                onCancel={onCancel}
                visible={visible}
                width={500}
                closeIcon={<AppImage src={close} />}
                title={<Title level={3} className="capitalize no-margin">Start new message</Title>}
                className="new-message-user-list"
                okText={'Start New Message'}
                okButtonProps={{ disabled: !selectedUser }}
                onOk={() => startNewMessage(selectedUser)}
            >
                <Input
                    placeholder="Search"
                    className="message-input"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                />
                <div className="messages-page">

                    <div className="user-list">

                        <div className="user-list-container">
                            {users?.filter(user => user.username.indexOf(searchText) >= 0).map((user, index) => <>
                                <div key={index} className={"user-container pointer"} onClick={() => setSelectedUser(user.username)} >
                                    {user.username === selectedUser && <div className="active" />}
                                    <Row wrap={false} align="middle">
                                        <Col flex="none" className="mb-5">
                                            <AppImage src={user.avatar} className="user-image" />
                                        </Col>
                                        <Col flex="auto">
                                            <p className="fs-18 no-margin">{user.username}</p>
                                        </Col>
                                    </Row>
                                </div>
                                {users?.length !== (index + 1) && <hr className="no-margin" />}
                            </>)}
                        </div>
                    </div>
                </div>
            </Modal>
        </>

    )
}

export default NewMessage;
