import {
  combineReducers,
  configureStore,
} from "@reduxjs/toolkit";
import thunk from 'redux-thunk'

import auth from '../views/auth/slice';
import home from '../views/main/home/slice';
import leaderboard from '../views/main/leaderBoard/slice';
import ideaDetails from '../views/main/ideaDetails/slice';
import profile from '../views/main/profile/slice';
import messages from '../views/main/messages/slice';
import notifications from '../views/main/notificationDropdown/slice';
import search from '../views/main/searchInput/slice';



const reducers = combineReducers({
    auth,
    home,
    leaderboard,
    ideaDetails,
    profile,
    messages,
    notifications,
    search,
});

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});
