import { createSlice, isPending, isRejected } from "@reduxjs/toolkit";

import {
    getIdeas,
    postIdeas,
    likeIdea,
} from "./thunk";

const thunks = [getIdeas, postIdeas, likeIdea];

const initialState = {
    status: "idle",
    errorMessage: '',
    ideas: [],
};

export const slice = createSlice({
    name: "home",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(likeIdea.fulfilled, (state, action) => {
                state.status = "idle";
            })
            .addCase(postIdeas.fulfilled, (state, action) => {
                state.status = "idle";
            })
            .addCase(getIdeas.fulfilled, (state, action) => {
                state.status = "idle";
                state.ideas = action?.payload;
            })
            .addMatcher(isPending(...thunks), (state) => {
                state.status = "loading";
            })
            .addMatcher(isRejected(...thunks), (state, action) => {
                state.status = "failed";
                state.errorMessage = action?.error?.message;
            });
    },
});

export const selectStatus = (state) => state.home.status === "loading";
export const selectIdeas = (state) => state.home.ideas;


export default slice.reducer;
