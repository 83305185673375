import React, { useEffect } from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import Main from './views/main/main';

import routes from './routes';
import { useSelector, useDispatch } from "react-redux";
import { selectUser, selectStatus, selectToken } from './views/auth/slice';
import { setData } from './views/auth/thunk';
import { getMyProfile } from './views/main/profile/thunk';
import { Spin } from 'antd'




const App = () => {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const isLoading = useSelector(selectStatus);
    const token = useSelector(selectToken);

    useEffect(async () => {
        const data = localStorage.getItem("@user");
        const userData = JSON.parse(data);
        if (userData?.token && userData?.token !== token) {
            await dispatch(setData(userData));
            dispatch(getMyProfile());
        } else {
            dispatch(setData({}))
        }
    }, [])

    return (
        <>
            {isLoading ? <div className="loader-container"><Spin /></div> :
                routes.map((route, index) => (
                    <Route
                        key={index}
                        path={route.path}
                        exact={route.exact}
                        render={() => {
                            if (route.private && !user) {
                                return <Redirect to="/login" />
                            } else if (route.isChild) {
                                return (
                                    <Main>
                                        {route.component({})}
                                    </Main>
                                )
                            } else {
                                return route.component({});
                            }
                        }}
                    />
                ))}


        </>
    );
}

export default App;